import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "shadow rounded-lg overflow-hidden flex flex-col bg-white h-full" }
const _hoisted_2 = { class: "p-2 flex gap-2" }
const _hoisted_3 = {
  key: 0,
  class: "px-2 pt-2 font-bold flex-1 truncate text-left text-[16px]"
}
const _hoisted_4 = { class: "pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "header")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}