
import { computed, defineComponent, onBeforeMount, Ref, ref, watch } from "vue";
import ComponentCard from "@/components/atoms/ComponentCard.vue";
import NewShareholderModal from "@/components/organisms/NewShareholderModal.vue";
import { ShareholderService } from "@/services/shareholder/ShareholderService";
import TextField from "@/components/atoms/TextField.vue";
import { useRouter } from "vue-router";
import { ShareholderResume } from "@/services/shareholder/types";
import NewModal from "../atoms/NewModal.vue";
import { formatRut } from "@/services/utils";

import IconShareholderTable from "@/components/atoms/IconShareholderTable.vue";
import DeleteShareholderButton from "../atoms/tables/DeleteShareholderButton.vue";
import * as XLSX from "xlsx";
import TableComponent from "../molecules/TableComponent.vue";

export default defineComponent({
  setup() {
    const columnsUnits = ref([
      { name: "identificationRut", label: "Rut" },
      { name: "identificationName", label: "Nombre" },
      { name: "investorNumberA", label: "ID A" },
      { name: "totalSharesA", label: "Total Acc. A" },
      { name: "investorNumberB", label: "ID B" },
      { name: "totalSharesB", label: "Total Acc. B" },
    ]);

    const showNewShareholderModal = ref(false);
    const rowSelection = ref<string>();
    const idShareholderSelected = ref<number>();
    const shareholderService = new ShareholderService();
    const router = useRouter();
    const shareholders: Ref<ShareholderResume[]> = ref([]);
    const searchText = ref("");
    const isVisibleConfirmationModal = ref(false);

    const currentPage: Ref<number> = ref(1);
    const totalPages: Ref<number> = ref(0);
    const perPage: Ref<number> = ref(0);
    const showFirstPages = computed(() => currentPage.value <= perPage.value);
    const showLastPages = computed(
      () => currentPage.value > totalPages.value - perPage.value
    );

    watch(searchText, () => {
      currentPage.value = 1;
      getShareholders(
        currentPage.value,
        sortField.value,
        isSortAscending.value,
        searchText.value
      );
    });

    const filteredShareholders = computed(
      () =>
        sortedShareholders.value.filter((shareholder) => {
          const name = (
            shareholder.firstName +
            " " +
            shareholder.lastName
          ).toLowerCase();
          const nominativeName = shareholder.identificationName?.toLowerCase();
          const search = searchText.value.toLowerCase();
          const rut = shareholder.rut.toLowerCase();
          const businessName = shareholder.businessName?.toLowerCase();
          const investorNumberB = shareholder?.investorNumberB
            ?.toString()
            .toLowerCase();
          return true;
        })
      // .sort((a: any, b: any) => {
      //         const aValue = a[sortField.value as keyof ShareholderResume] || "";
      //         const bValue = b[sortField.value as keyof ShareholderResume] || "";

      //         if (typeof aValue === 'number' && typeof bValue === 'number') {
      //             return isSortAscending.value ? aValue - bValue : bValue - aValue;
      //         }

      //         if (typeof aValue === 'string' && typeof bValue === 'string') {
      //             const aValueLower = aValue.toLowerCase();
      //             const bValueLower = bValue.toLowerCase();

      //             if (aValueLower < bValueLower) {
      //                 return isSortAscending.value ? -1 : 1;
      //             }
      //             if (aValueLower > bValueLower) {
      //                 return isSortAscending.value ? 1 : -1;
      //             }
      //         }

      //         return 0;
      //     })
    );
    const rowData = computed(() => {
      return shareholders.value.map((shareholder: ShareholderResume) => {
        return {
          identificationIconName: shareholder.identificationIconName,
          identificationRut: shareholder.identificationRut,
          identificationName: shareholder.identificationName,
          investorNumberA: shareholder.investorNumberA,
          totalSharesA: shareholder.totalSharesA,
          investorNumberB: shareholder?.investorNumberB,
          totalSharesB: shareholder.totalSharesB,
          shareholderId: shareholder.id,
        };
      });
    });
    onBeforeMount(() => {
      rowSelection.value = "single";
    });

    const sortField = ref("");
    const isSortAscending = ref(true);

    const sortedShareholders = computed(() => {
      if (sortField.value) {
        return [...shareholders.value].sort((a, b) => {
          const aValue: any =
            a[sortField.value as keyof ShareholderResume] || "";
          const bValue: any =
            b[sortField.value as keyof ShareholderResume] || "";

          if (
            sortField.value === "investorNumberB" ||
            sortField.value === "investorNumberA"
          ) {
            return isSortAscending.value ? aValue - bValue : bValue - aValue;
          }

          if (typeof aValue === "string" && typeof bValue === "string") {
            const aValueLower = aValue.toLowerCase();
            const bValueLower = bValue.toLowerCase();

            if (aValueLower < bValueLower) {
              return isSortAscending.value ? -1 : 1;
            }
            if (aValueLower > bValueLower) {
              return isSortAscending.value ? 1 : -1;
            }
          }

          return 0;
        });
      }

      return shareholders.value;
    });

    function changeSortField(field: string) {
      if (sortField.value === field) {
        isSortAscending.value = !isSortAscending.value;
      } else {
        sortField.value = field;
        isSortAscending.value = true;
      }
      getShareholders(
        currentPage.value,
        sortField.value,
        isSortAscending.value,
        searchText.value
      );
    }
    function deleteHandler(shareholderId: number, e: Event) {
      e.stopPropagation();
      isVisibleConfirmationModal.value = !isVisibleConfirmationModal.value;
      idShareholderSelected.value = shareholderId;
    }
    function cancelDeleteHandler() {
      isVisibleConfirmationModal.value = !isVisibleConfirmationModal.value;
      idShareholderSelected.value = 0;
    }
    async function deleteShareholder() {
      if (!idShareholderSelected.value) return false;
      const response = await shareholderService.deleteShareholder(
        idShareholderSelected.value
      );
      if (!response.success)
        alert("Algo salió mal, vuelva a recargar la página.");
      isVisibleConfirmationModal.value = !isVisibleConfirmationModal.value;
      getShareholders(
        currentPage.value,
        sortField.value,
        isSortAscending.value,
        searchText.value
      );
    }

    async function getShareholders(
      page: number,
      order = "",
      typeOrder = false,
      search = ""
    ) {
      const response = await shareholderService.getShareholders(
        page,
        order,
        typeOrder,
        search
      );

      if (!response.success) {
        console.log("Ha ocurrido un error al intentar obtener los accionistas");
        shareholders.value = [];
        return;
      }
      shareholders.value = response.data;
      currentPage.value = response.page;
      totalPages.value = response.total_pages;
      perPage.value = response.per_page;
    }

    getShareholders(
      currentPage.value,
      sortField.value,
      isSortAscending.value,
      searchText.value
    );

    const exportToExcel = () => {
      const ws = XLSX.utils.json_to_sheet(filteredShareholders.value);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Datos");
      XLSX.writeFile(wb, "lista_accionistas.xlsx");
    };

    function toShareholderDetail(id: number) {
      router.push({ name: "shareholderDetail", params: { id: id } });
    }

    /*const totalPages = computed(() =>
      //Math.ceil(props.items.length / perPageSelect.value)
    );*/
    const visiblePages = computed(() => {
      if (showFirstPages.value) {
        return Array.from(
          { length: Math.min(perPage.value, totalPages.value) },
          (_, index) => index + 1
        );
      } else if (showLastPages.value) {
        return Array.from(
          { length: Math.min(perPage.value, totalPages.value) },
          (_, index) => totalPages.value - 10 + index
        );
      } else {
        return Array.from(
          { length: perPage.value },
          (_, index) => currentPage.value - 5 + index
        );
      }
    });
    const goToPage = (page: number) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        getShareholders(
          currentPage.value,
          sortField.value,
          isSortAscending.value,
          searchText.value
        );
      }
    };
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        getShareholders(
          currentPage.value,
          sortField.value,
          isSortAscending.value,
          searchText.value
        );
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        getShareholders(
          currentPage.value,
          sortField.value,
          isSortAscending.value,
          searchText.value
        );
      }
    };
    const lastPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value = totalPages.value;
        getShareholders(
          currentPage.value,
          sortField.value,
          isSortAscending.value,
          searchText.value
        );
      }
    };
    const firstPage = () => {
      currentPage.value = 1;
      getShareholders(
        currentPage.value,
        sortField.value,
        isSortAscending.value,
        searchText.value
      );
    };

    return {
      showNewShareholderModal,
      filteredShareholders,
      searchText,
      isVisibleConfirmationModal,
      idShareholderSelected,
      sortField,
      isSortAscending,
      getShareholders,
      toShareholderDetail,
      deleteHandler,
      cancelDeleteHandler,
      changeSortField,
      deleteShareholder,
      columnsUnits,
      formatRut,
      currentPage,
      totalPages,
      perPage,
      firstPage,
      lastPage,
      nextPage,
      previousPage,
      goToPage,
      visiblePages,
      columnDefs: [
        {
          headerName: "",
          field: "identificationIconName",
          cellRenderer: IconShareholderTable,
          width: 70,
        },
        { headerName: "Rut", field: "identificationRut" },
        {
          headerName: "Nombre",
          field: "identificationName",
          sortable: true,
          width: 350,
        },
        { headerName: "IDA", field: "investorNumberA" },
        {
          headerName: "Total acciones A",
          field: "totalSharesA",
          sortable: true,
        },
        { headerName: "IDB", field: "investorNumberB", sortable: true },
        { headerName: "Total acciones B", field: "totalSharesB" },
        {
          field: "acciones",
          cellRenderer: DeleteShareholderButton,
          cellRendererParams: {
            clicked(id: number, e: Event) {
              deleteHandler(id, e);
            },
          },
        },
      ],
      rowData,
      exportToExcel,
    };
  },

  components: { NewModal, ComponentCard, NewShareholderModal, TextField },
});
