
import { AuthService } from "@/services/auth/AuthService";
import { useAuthStore } from "@/stores/auth";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import AccountMenu from "./AccountMenu.vue";
import NotificationMenu from "./NotificationMenu.vue";
import Modal from "../atoms/Modal.vue";

interface Route {
  name: string;
  label: string;
  param?: Record<string, string | number>;
}

export default defineComponent({
  components: { NotificationMenu, Modal, AccountMenu },
  setup() {
    const router = useRouter();
    const authService = new AuthService();
    const authStore = useAuthStore();
    const isVisibleConfirmationModal = ref<boolean>(false);

    const isMenuOpen = ref<boolean>(false);

    const user = authStore.user;

    async function logout() {
      if (!authStore.token) return;

      const response = await authService.logout(authStore.token);

      if (response.success) {
        router.push({ name: "login" });
        authStore.logout();
      }
    }

    const routes: Route[] = [
      { name: "params", label: "Parámetros" },
      { name: "investments", label: "Inversiones" },
      { name: "dividends", label: "Dividendos" },
      { name: "shareholders", label: "Accionistas" },
      { name: "users", label: "Usuarios" },
    ];

    return {
      user,
      routes,
      logout,
      authStore,
      isVisibleConfirmationModal,
      isMenuOpen,
    };
  },
});
