import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { Response } from "../types";
import { fetchGetFileBodyRequired, getResponse, keysToSnake } from "../utils";
import {
  CreateContractData,
  ShareholderBaseMovement,
  ShareholderMovement,
  UploadContractData,
} from "./types";

export enum MovementEnum {
  "Accion A" = 0,
  "Accion B" = 1,
  "" = 2,
}
export class MovementService extends AuthenticatedService {
  async createContract(createContractData: CreateContractData) {
    // ! se esta usando ??
    const url = `${getEnvConfig().apiURL}/movements/contracts/create`;
    const body = keysToSnake(createContractData);
  }
  async uploadContract(uploadContractData: UploadContractData) {
    const formUploadContractData = new FormData();
    formUploadContractData.append(
      "movement_id",
      uploadContractData.movementId.toString()
    );
    formUploadContractData.append(
      "signed_contract",
      uploadContractData.signedContract
    );
    const url = `${getEnvConfig().apiURL}/movements/contracts/save`;

    return getResponse(
      url,
      this.token,
      "POST",
      undefined,
      formUploadContractData
    );
  }
  async getContract(movementId: number) {
    const url = `${getEnvConfig().apiURL}/movements/contracts/get`;
    const body = keysToSnake({ movementId });
    return fetchGetFileBodyRequired(url, this.token, "POST", "file", body);
  }
  async deleteMovement(movementId: number) {
    const url = `${getEnvConfig().apiURL}/movements/cancel`;
    return getResponse(url, this.token, "POST", keysToSnake({ movementId }));
  }

  async registerMovements(
    shareholderMovement: ShareholderBaseMovement
  ): Promise<Response<null>> {
    const data = keysToSnake(shareholderMovement);
    const url = `${getEnvConfig().apiURL}/movements/create`;

    return getResponse(url, this.token, "POST", data, undefined);
  }

  async getShareholderMovement(
    shareholderId: number
  ): Promise<Response<ShareholderMovement[]>> {
    const url = `${getEnvConfig().apiURL}/movements/${shareholderId}/all`;

    return getResponse(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      ShareholderMovement
    );
  }
}
