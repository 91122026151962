import { Shareholder } from "@/services/shareholder/types";
import { defineStore } from "pinia";
import { ShareholderService } from "@/services/shareholder/ShareholderService";

export const useShareholderStore = defineStore("shareholder", {
  state: () => {
    let shareholder: Shareholder | undefined;
    const shareholderService = new ShareholderService();

    return {
      shareholder,
      shareholderService,
    };
  },

  actions: {
    async query(id: number) {
      this.shareholderService
        .getShareholderDetail(id)
        .then(({ data }) => {
          this.shareholder = data
        });
    },
  },

  getters: {
    getData(state) {
      return state.shareholder;
    },
  },
});

export type shareholderStore = ReturnType<typeof useShareholderStore>;
