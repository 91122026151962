
import { User } from "@/services/auth/types";
import { computed, defineComponent, ref, Ref, markRaw } from "vue";
import { useRouter } from "vue-router";
import ShareholderCard from "@/components/molecules/ShareholderCard.vue";
import TabTemplate from "@/components/templates/TabTemplate.vue";
import ShareholderIdentificationTab from "@/views/admin/tabs/ShareholderIdentificationTab.vue";
import ShareholderSharesTab from "@/views/admin/tabs/ShareholderSharesTab.vue";
import { useShareholderStore } from "@/stores/shareholder";
import ShareholderNotificationSettingsTab from "./tabs/ShareholderNotificationSettingsTab.vue";

export default defineComponent({
  components: { ShareholderCard, TabTemplate },

  setup() {
    const shareholderStore = useShareholderStore();
    const router = useRouter();
    const shareholder: Ref<User | null> = ref(null);

    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );

    shareholderStore.query(shareholderId.value);

    return {
      shareholder,
      ShareholderCard,
      ShareholderIdentificationTab,
      ShareholderSharesTab,
      ShareholderNotificationSettingsTab,
      markRaw,
    };
  },
});
