
export class Parameter {
  constructor(
    readonly id: number| undefined,
    public stockSaleWindows: string[][],
    readonly minMonhtsToSellShares: number,
    readonly minShares: number,
    readonly ufSharesPrice: number,
    readonly totalSharesGroupA: number,
    readonly totalSharesGroupB: number,
    public dividendPaymentPeriod: string[],
  ) {}
}