import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2" }
const _hoisted_2 = { key: 3 }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputImage = _resolveComponent("InputImage")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_SwitchButton = _resolveComponent("SwitchButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_InputImage, {
          key: 0,
          onUploadFile: _ctx.uploadFile,
          class: "mt-4"
        }, null, 8, ["onUploadFile"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TextField, {
      type: "text",
      name: "Título",
      label: 'Título',
      "hide-details": "",
      model: _ctx.title,
      "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
      hasError: _ctx.titleFieldHasError
    }, null, 8, ["model", "hasError"]),
    _createVNode(_component_TextField, {
      type: "textarea",
      name: "description",
      label: 'Descripción',
      "hide-details": "",
      model: _ctx.description,
      "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
      hasError: _ctx.descriptionFieldHasError
    }, null, 8, ["model", "hasError"]),
    _createVNode(_component_Selector, {
      label: "Estado",
      class: "w-full",
      items: ['Disponible', 'Arrendado', 'Vendido'],
      model: _ctx.investmentStatus,
      "onUpdate:model": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.investmentStatus) = $event)),
      hasError: _ctx.investmentStatusFielHasError,
      values: _ctx.investmentSt
    }, null, 8, ["model", "hasError", "values"]),
    _createVNode(_component_TextField, {
      type: "number",
      name: "value",
      label: "Valor de compra en UF",
      "hide-details": "",
      model: _ctx.value,
      "onUpdate:model": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
      hasError: _ctx.valueFieldHasError
    }, null, 8, ["model", "hasError"]),
    (_ctx.monthlyProfitabilityClp)
      ? (_openBlock(), _createBlock(_component_TextField, {
          key: 1,
          type: "number",
          name: "profiability",
          label: "Renta mensual en CLP",
          "hide-details": "",
          model: _ctx.monthlyProfitabilityClp,
          "onUpdate:model": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.monthlyProfitabilityClp) = $event)),
          hasError: _ctx.profiabilityClpFieldHasError
        }, null, 8, ["model", "hasError"]))
      : _createCommentVNode("", true),
    (_ctx.monthlyProfitabilityUf)
      ? (_openBlock(), _createBlock(_component_TextField, {
          key: 2,
          type: "number",
          name: "profiability",
          label: "Renta mensual en UF",
          "hide-details": "",
          model: _ctx.monthlyProfitabilityUf,
          "onUpdate:model": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.monthlyProfitabilityUf) = $event)),
          hasError: _ctx.profiabilityUfFieldHasError
        }, null, 8, ["model", "hasError"]))
      : _createCommentVNode("", true),
    (!_ctx.monthlyProfitabilityUf && !_ctx.monthlyProfitabilityClp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, [
            _createVNode(_component_SwitchButton, {
              switchOptions: _ctx.dividendValuesOption,
              defaultIndexSelection: _ctx.IndexTypeSelected,
              label: 'Tipo de renta',
              onSwitchTypeSelection: _ctx.getingOptionTypeSelected
            }, null, 8, ["switchOptions", "defaultIndexSelection", "onSwitchTypeSelection"]),
            _createVNode(_component_TextField, {
              type: "number",
              name: "profiability",
              label: 
            _ctx.IndexTypeSelected
              ? 'Renta mensual en pesos chilenos'
              : 'Renta mensual en UF'
          ,
              "hide-details": "",
              model: _ctx.profiability,
              "onUpdate:model": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.profiability) = $event)),
              hasError: _ctx.profiabilityFieldHasError
            }, null, 8, ["label", "model", "hasError"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "success ml-auto my-2",
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args))),
        disabled: _ctx.formHasError || _ctx.disableButton
      }, " Editar ", 8, _hoisted_4)
    ])
  ]))
}