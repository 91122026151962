import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse } from "../utils";
import { Response } from "../types";
import { TotalCapitalMetric, TotalPaidMetric, TotalShareholdersMetric, TotalSubsMetric } from "./types";

export class MetricService extends AuthenticatedService {

    async getTotalCapital(): Promise<Response<TotalCapitalMetric>> {
        const url = `${getEnvConfig().apiURL}/admin/total_capital`

        return getResponse(url, this.token, "GET")
    }

    async getTotalShareholders(): Promise<Response<TotalShareholdersMetric>> {
        const url = `${getEnvConfig().apiURL}/admin/total_shareholders`

        return getResponse(url, this.token, "GET")
    }

    async getPaidInCapital(): Promise<Response<TotalPaidMetric>> {
        const url = `${getEnvConfig().apiURL}/admin/paid_in_capital`

        return getResponse(url, this.token, "GET")
    }

    async getTotalSubs(): Promise<Response<TotalSubsMetric>> {
        const url = `${getEnvConfig().apiURL}/admin/total_shares`

        return getResponse(url, this.token, "GET")
    }
}