
import Modal from "@/components/atoms/Modal.vue";
import Selector from "@/components/atoms/Selector.vue";
import NewPaginationTableComponent from "@/components/molecules/NewPaginationTableComponent.vue";

import DividendDetailModal from "@/components/organisms/dividends/DividendDetailModal.vue";
import GenericDividensForm from "@/components/organisms/dividends/GenericDividendsForm.vue";
import { DividendService } from "@/services/dividends/dividendService";
import {
  PaymentStatus,
  DividendLabelOptionType,
  DividendType,
  formOptionsType,
  DividendsFilter,
  DividendStatusType,
  MultiplePaymentsData,
  DividendOptionType,
} from "@/services/dividends/types";
import { reformatDate, toBadgeFormat } from "@/services/utils";
import { defineComponent, onMounted, ref, watch, Ref } from "vue";

export default defineComponent({
  components: {
    DividendDetailModal,
    NewPaginationTableComponent,
    GenericDividensForm,
    Selector,
    Modal,
  },
  setup() {
    const dividendNewStatus = ref<DividendStatusType>(
      DividendStatusType.PENDING
    );
    const dividendService = new DividendService();
    const showDeleteConfirmation = ref<boolean>(false);
    const clickedOutsideModal = ref<boolean>(false);
    const selectedDividendToDelete = ref<number>();
    const showNewDividendModal = ref<boolean>(false);
    const isVisibleConfirmationModal = ref<boolean>(false);
    const showDetailModal = ref<boolean>(false);
    const showFilterModal = ref<boolean>(false);
    const dividendPayments = ref<PaymentStatus[]>([]);
    const selectedDividendPayments = ref<number[]>([]);
    const areAllDividendsSelected = ref<boolean>(false);
    const dividendDataSelected = ref<PaymentStatus>();
    const dividendFilter = ref<DividendsFilter>(new DividendsFilter());

    const currentPage: Ref<number> = ref(1);
    const totalPages: Ref<number> = ref(0);
    const perPage: Ref<number> = ref(0);

    const columnsUnits = ref([
      { name: "pendingDate", label: "Fecha" },
      { name: "accountOwnerName", label: "Destinatario" },
      { name: "totalClpStringFormat", label: "Monto" },
      { name: "statusStringFormat", label: "Estado" },
      { name: "accountNumber", label: "Cuenta destino" },
    ]);
    const columnsDividendos = ref([
      { name: "cutoffDate", label: "Fecha de corte" },
      { name: "distributionDate", label: "Fecha de distribución" },
      { name: "divTypeString", label: "Tipo de dividendo" },
      { name: "ufPerShare", label: "Uf por acción" },
      { name: "totalShares", label: "Cant. acciones" },
      { name: "totalShareholders", label: "Cant. accionistas" },
      { name: "totalPayedClp", label: "Total pagado" },
    ]);
    const dividends = ref<DividendType[]>([]);
    onMounted(async () => {
      getAllDividends(currentPage.value);
      //getDividents();
    });

    const otherCurrentPage = (newPage: number) => {
      currentPage.value = newPage;
      getAllDividends(currentPage.value);
    };

    async function getAllDividends(page = 0) {
      let res = await dividendService.getAllDividends(page);
      if (!res.success) {
        alert(
          "Ha ocurrido un error al cargar los dividendos. Vuelve a intentarlo."
        );
        dividends.value = [];
        return false;
      }
      if (res.success) {
        dividends.value = res.data;
        currentPage.value = res.page;
        totalPages.value = res.total_pages;
        perPage.value = res.per_page;

        dividends.value.forEach((dividend) => {
          dividend.cutoffDate = reformatDate(dividend.cutoffDate.toString());
          dividend.totalPayedClp =
            "$" +
            toBadgeFormat(
              parseInt(dividend?.totalPayedClp?.toString() ?? "0") ?? 0
            );
          dividend.distributionDate = reformatDate(
            dividend.distributionDate.toString()
          );
          dividend.ufPerShare =
            dividend.divType.toString() == "UF_PER_SHARE"
              ? dividend.ufPerShare
              : "-";
          dividend.divTypeString =
            dividend.divType.toString() == "UF_PER_SHARE"
              ? "UF por Acción"
              : "Valor total";
        });

        dividends.value = dividends.value.sort((dividend1, dividend2) => {
          return (
            new Date(dividend2.distributionDate).getTime() -
            new Date(dividend1.distributionDate).getTime()
          );
        });
      }
    }
    async function getDividents() {
      /*const response = await dividendService.getDividendPayments();
      if (!response.success) {
        dividendPayments.value = [];
        return;
      }

      dividendPayments.value = response.data;

      dividendPayments.value = dividendPayments.value.sort(
        (dividend1, dividend2) => {
          return (
            new Date(dividend2.pendingDate).getTime() -
            new Date(dividend1.pendingDate).getTime()
          );
        }
      );
      dividendPayments.value = dividendPayments.value.map((dividend) => {
        let newRow = {
          ...dividend,
          pendingDate: reformatDate(
            dividend.pendingDate.toString(),
            true,
            false
          ),
          totalClpStringFormat: dividend.totalClp
            ? "$ " + toBadgeFormat(dividend.totalClp)
            : "-",
          statusStringFormat: dividend.status
            ? getPrettyPrintStatus(dividend.status)
            : "-",
        };

        return newRow;
      });*/
    }

    watch(areAllDividendsSelected, () => {
      if (areAllDividendsSelected.value) {
        let selectAllFromDate = dividendPayments.value.map((dividend) => {
          return dividend.id;
        });
        selectedDividendPayments.value = selectAllFromDate;
      } else {
        selectedDividendPayments.value = [];
      }
    });

    async function aplyFilter(dividendFilter: DividendsFilter) {
      await getDividents();
      if (dividendFilter.dateRange != undefined) {
        dividendPayments.value = dividendPayments.value.filter((dividend) => {
          if (dividendFilter.dateRange != undefined) {
            return (
              dividendFilter.dateRange[0] <= new Date(dividend.pendingDate) &&
              new Date(dividend.pendingDate) <= dividendFilter.dateRange[1]
            );
          }
        });
      }
      if (dividendFilter.filterDate != undefined) {
        const filterDateString = dividendFilter.filterDate
          .toISOString()
          .split("T")[0];

        dividendPayments.value = dividendPayments.value.filter((dividend) => {
          const dividendDate = new Date(dividend.pendingDate);
          const dividendDateString = dividendDate.toISOString().split("T")[0];

          if (filterDateString === dividendDateString) {
            return true;
          }
          return false;
        });
      }
      if (dividendFilter.shareholderId != undefined) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.shareholderId == dividendFilter.shareholderId;
        });
      }
      if (!dividendFilter.showPayed) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.status != DividendStatusType.PAID;
        });
      }
      if (!dividendFilter.showPending) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.status != DividendStatusType.PENDING;
        });
      }
      if (!dividendFilter.showRejected) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.status != DividendStatusType.REJECTED;
        });
      }
      if (dividendFilter.dividendId != undefined) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.dividendId == dividendFilter.dividendId;
        });
      }
    }
    const updateSelectedItems = (selected: any) => {
      alert("actualizando los seleccionados");

      selectedDividendPayments.value = selected;
      // selectedItems.value = selected;
    };
    async function modifyMultiplePayments() {
      const multiplePaymentData = new MultiplePaymentsData(
        selectedDividendPayments.value,
        dividendNewStatus.value
      );
      const res = await dividendService.editMultiplePayment(
        multiplePaymentData
      );
      if (!res.data) {
        alert("Ocurrio un error inesperado");
      }
      alert(
        "Se ha cambiado el estado de los pagos seleccionados de forma exitosa"
      );
      selectedDividendPayments.value = [];
      areAllDividendsSelected.value = false;
      dividendFilter.value = new DividendsFilter();
      aplyFilter(dividendFilter.value);
      //getDividents();
    }
    async function deleteHandler(dividendId: number, e: Event) {
      e.stopPropagation();
      isVisibleConfirmationModal.value = true;
      selectedDividendToDelete.value = dividendId;
    }
    async function deleteDividend() {
      dividendFilter.value = new DividendsFilter();
      aplyFilter(dividendFilter.value);
      if (selectedDividendToDelete.value == undefined) return null;
      let res = await dividendService.deleteDividend(
        selectedDividendToDelete.value
      );
      if (res.success) {
        alert("dividendo eliminado con éxito.");
      }
      getAllDividends(currentPage.value);
      //getDividents();
      isVisibleConfirmationModal.value = false;
    }
    function filterDividendHandler(dividendId: number, e: Event) {
      if (dividendId == -1) {
        dividendFilter.value = new DividendsFilter();
        aplyFilter(dividendFilter.value);

        return false;
      }
      e.stopPropagation();
      dividendFilter.value.dividendId = dividendId;

      aplyFilter(dividendFilter.value);
    }
    function tableRowSelectionHandler(dividendSelected: PaymentStatus) {
      dividendDataSelected.value = dividendSelected;
      showDetailModal.value = true;
    }
    function closeFilterModal() {
      showFilterModal.value = showFilterModal.value
        ? false
        : showFilterModal.value;
    }
    function closeNewDividendModal() {
      showNewDividendModal.value = showNewDividendModal.value
        ? false
        : showNewDividendModal.value;
    }
    function closeNewDetailModal() {
      //dontRepit yourself
      showDetailModal.value = showDetailModal.value
        ? false
        : showDetailModal.value;
    }
    function getPrettyPrintStatus(status: DividendStatusType) {
      switch (status) {
        case DividendStatusType.PAID:
          return "Pagado";
        case DividendStatusType.PENDING:
          return "Pendiente";
        case DividendStatusType.REJECTED:
          return "Rechazado";
        default:
          break;
      }
      return status;
    }
    function genericButtonHandler(option: formOptionsType) {
      showNewDividendModal.value = false;
      showFilterModal.value = false;
      switch (option) {
        case formOptionsType.newDividendForm:
          showNewDividendModal.value = true;
          break;

        case formOptionsType.filterForm:
          showFilterModal.value = true;
          break;

        default:
          break;
      }
    }
    function downloadExcel() {
      dividendService.downloadDividendsList();
    }

    return {
      genericButtonHandler,
      areAllDividendsSelected,
      closeFilterModal,
      closeNewDividendModal,
      reformatDate,
      getAllDividends,
      otherCurrentPage,
      aplyFilter,
      getPrettyPrintStatus,
      tableRowSelectionHandler,
      getDividents,
      toBadgeFormat,
      closeNewDetailModal,
      modifyMultiplePayments,
      updateSelectedItems,
      dividendNewStatus,
      dividendFilter,
      deleteHandler,
      filterDividendHandler,
      deleteDividend,
      columnsUnits,
      columnsDividendos,
      isVisibleConfirmationModal,
      DividendLabelOptionType,
      selectedDividendPayments,
      dividendDataSelected,
      DividendStatusType,
      showDetailModal,
      showNewDividendModal,
      clickedOutsideModal,
      showFilterModal,
      formOptionsType,
      dividendPayments,
      dividends,
      showDeleteConfirmation,
      selectedDividendToDelete,
      downloadExcel,
      currentPage,
      totalPages,
      perPage,
    };
  },
});
