
import { computed, defineComponent, ref } from "vue";
import TextField from "@/components/atoms/TextField.vue";
// import SwitchButton from "@/components/atoms/SwitchButton.vue";
import {
  DividendCreateClass,
  DividendLabelOptionType,
  DividendOptionType,
} from "@/services/dividends/types";
import Selector from "@/components/atoms/Selector.vue";
import { DividendRatioOptions } from "@/services/dividends/types";
import InPlaceMessage from "../InPlaceMessage.vue";
import es from "@vuepic/vue-datepicker";
import NewModal from "@/components/atoms/NewModal.vue";
import { reformatDate } from "@/services/utils";

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  components: {
    TextField,
    // SwitchButton,
    Selector,
    InPlaceMessage,
    NewModal,
  },
  emits: ["submitNewDividendData"],
  setup(props, { emit }) {
    const flow = ref(["year", "month", "calendar"]);
    const selectedGroupARatio = ref<string>(DividendRatioOptions.equalPart);
    const selectedCutOffDate = ref<Date>(new Date());
    const selectedDistributionDate = ref<Date>(new Date());
    const isVisibleConfirmationModal = ref<boolean>(false);

    const isLoadingForm = computed(() => {
      return props.isLoading;
    });

    const isButtonDisabled = ref<boolean>(false);
    const dividendLabelsOption = ref<string[]>([
      DividendLabelOptionType.UF_PER_SHARE,
      DividendLabelOptionType.PROPERTY_SALE,
    ]);
    const IndexTypeSelected = ref<number>(0);
    const genericValueAmount = ref<number>(0);
    const dividendARatio = ref<number>(0);
    const dividendValuesOption = ref<string[]>([
      DividendOptionType.UF_SHARE,
      DividendOptionType.PROPERTY_SALE,
    ]);
    function isNullOrEmpty(value?: string | number | null | File): boolean {
      return !value || value == null || value == "" || value == 0;
    }
    const amountFieldHasError = computed(() => {
      return isNullOrEmpty(genericValueAmount.value);
    });
    const formHasError = computed(() => {
      const requiredFields = [genericValueAmount.value];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [amountFieldHasError.value];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });
    function closeModal() {
      isVisibleConfirmationModal.value = false;
    }

    function updateOptionType(event: Event) {
      const target = event.target as HTMLInputElement;
      IndexTypeSelected.value = parseInt(target.value);
    }
    function submitHandler() {
      isButtonDisabled.value = true;
      let dividendOptionTypeSelected = DividendOptionType.UF_SHARE;
      let ufPerShare = undefined;
      let totalUf = undefined;
      let groupARatio: number | undefined = dividendARatio.value;

      if (
        DividendOptionType.UF_SHARE ==
        dividendValuesOption.value[IndexTypeSelected.value]
      ) {
        ufPerShare = genericValueAmount.value;
      } else {
        dividendOptionTypeSelected = DividendOptionType.PROPERTY_SALE;
        totalUf = genericValueAmount.value;
      }
      if (selectedGroupARatio.value != DividendRatioOptions.custom) {
        groupARatio = undefined;
      }
      const cutOffStrDate = dateToNaiveISOString(selectedCutOffDate.value);
      const distributionStrDate = dateToNaiveISOString(
        selectedDistributionDate.value
      );
      const valuesDividendForm = new DividendCreateClass(
        dividendOptionTypeSelected,
        ufPerShare,
        totalUf,
        distributionStrDate,
        cutOffStrDate,
        groupARatio
      );
      emit("submitNewDividendData", valuesDividendForm);
      //isVisibleConfirmationModal.value = false;
      isButtonDisabled.value = true;
    }
    function dateToNaiveISOString(dateObj: Date) {
      // Use .toISOString() to get a string in the format 'YYYY-MM-DDTHH:MM:SS.sssZ'
      let isoStr = dateObj.toISOString();

      // Remove the 'T', the milliseconds and the 'Z' to get a string in the format 'YYYY-MM-DD HH:MM:SS'
      isoStr = isoStr.replace("T", " ");
      isoStr = isoStr.substring(0, isoStr.length - 5);

      return isoStr;
    }
    function getDescription() {
      let description = "";
      switch (selectedGroupARatio.value) {
        case DividendRatioOptions.custom:
          description =
            "Se reparte según la configuración seleccionada por el usuario. ";
          break;
        case DividendRatioOptions.equalPart:
          description =
            " Se reparte el 50% de la serie A y el 50% de la serie B. ";
          dividendARatio.value = 0.5;
          break;
        case DividendRatioOptions.default:
          description =
            "Se reparte el 30% de la serie A y el 70% de la serie B. ";
          dividendARatio.value = 0.3;
          break;
      }
      return description;
    }
    function getingOptionTypeSelected(newIndexTypeSelected: number) {
      IndexTypeSelected.value = newIndexTypeSelected;
      console.log(
        "obtenemos el valor seleccionado",
        dividendValuesOption.value[IndexTypeSelected.value]
      );
    }
    return {
      isLoadingForm,
      submitHandler,
      getingOptionTypeSelected,
      getDescription,
      updateOptionType,
      reformatDate,
      selectedCutOffDate,
      formHasError,
      amountFieldHasError,
      dividendARatio,
      DividendRatioOptions,
      selectedGroupARatio,
      DividendOptionType,
      dividendLabelsOption,
      IndexTypeSelected,
      genericValueAmount,
      isButtonDisabled,
      closeModal,
      flow,
      isVisibleConfirmationModal,
      selectedDistributionDate,
    };
  },
});
