
import { computed, defineComponent, ref } from "vue";
import { ShareholderService } from "@/services/shareholder/ShareholderService";
import NewModal from "@/components/atoms/NewModal.vue";
import TextField from "@/components/atoms/TextField.vue";
import Selector from "@/components/atoms/Selector.vue";
import { MovementService } from "@/services/movements/MovementService";
import { useRouter } from "vue-router";
import { shareMovementStore } from "@/stores/movements";
import { useShareholderStore } from "@/stores/shareholder";
import {
  ShareholderBaseMovement,
  MovementOptionTypes,
  OperationOptionTypes,
} from "@/services/movements/types";
import { ShareholderResume } from "@/services/shareholder/types";
import InPlaceMessage from "./InPlaceMessage.vue";
import { mdiEmail } from "@mdi/js";
import Datepicker, {
  VueDatePicker,
  PublicMethods,
} from "@vuepic/vue-datepicker";
import Swal from "sweetalert2";

export default defineComponent({
  components: {
    NewModal,
    TextField,
    Selector,
    InPlaceMessage,
    Datepicker,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:visible"],
  setup(props, { emit }) {
    const forceRegister = ref<boolean>(false);
    const dateWarningError = ref<string>(
      "El accionista aún no puede vender todas sus acciones"
    );
    const shareholderService = new ShareholderService();
    const flow = ref<VueDatePicker["flow"]>(["year", "month", "calendar"]);
    const movementService = new MovementService();
    const shareholders = ref<ShareholderResume[]>([]);
    const shareholderItems = computed(() => {
      let shareholderAux = shareholders.value
        .filter((shareholder) => shareholder.id != shareholderId.value)
        .map((shareholder) => {
          let AStringLabel = shareholder.investorNumberA
            ? " A  [" + shareholder.investorNumberA + "] -  "
            : "";
          let BStringLabel = shareholder?.investorNumberB
            ? " B  [" + shareholder?.investorNumberB + "] - "
            : "";
          return AStringLabel + BStringLabel + shareholder.identificationName;
        });
      return ["Dhelos", ...shareholderAux];
    });
    const shareholderValues = computed(() => {
      let shareholderAux = shareholders.value
        .filter((shareholder) => shareholder.id != shareholderId.value)
        .map((shareholder) => shareholder.id);
      return [-1, ...shareholderAux];
    });

    const shareholderStore = useShareholderStore();
    const movementsStore = shareMovementStore();
    const router = useRouter();
    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );
    const loading = ref(false);
    const hasError = ref(false);
    const errorMessage = ref("");

    const movementOptionSelected = ref<MovementOptionTypes>(
      MovementOptionTypes.ACTION_B
    );
    const shareholderSelected = ref<number>(-1);
    const operationOptionSelected = ref<OperationOptionTypes>(
      OperationOptionTypes.BUY
    );
    const movementInstance = ref<ShareholderBaseMovement>(
      new ShareholderBaseMovement()
    );
    const date = ref(new Date());
    const amount = ref<number | undefined>(undefined);

    async function getShareholders() {
      const response = await shareholderService.getShareholders();
      if (!response.success) {
        shareholders.value = [];
        return;
      }
      shareholders.value = response.data;
    }

    getShareholders();
    /**
     * Comprueba si el valor ingresado como parámetro es nulo o no definido.
     * @param value Valor a comprobar
     */
    function isNullOrEmpty(value?: string | number | null): boolean {
      return !value || value == null || value == "" || value == 0;
    }

    // Indica si el formulario tiene algun error de dato o valor requerido
    const formHasError = computed(() => {
      const requiredFields = [movementOptionSelected.value, amount.value];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [
        typeActionFieldHasError.value,
        amountFieldHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });

    const typeActionFieldHasError = computed(() => {
      return isNullOrEmpty(movementOptionSelected.value);
    });

    const amountFieldHasError = computed(() => {
      return isNullOrEmpty(amount.value);
    });

    async function submitNewMovement() {
      if (date.value) {
        movementInstance.value.createdAt = new Date(date.value).toISOString();
      }
      errorMessage.value = "";
      let destinatioShareholder = undefined;
      movementInstance.value.groupAAmount = undefined;
      movementInstance.value.groupBAmount = undefined;
      movementInstance.value.forceUpdate = forceRegister.value;
      shareholderSelected.value < 0
        ? (destinatioShareholder = undefined)
        : (destinatioShareholder = shareholderSelected.value);
      movementOptionSelected.value == MovementOptionTypes.ACTION_A
        ? (movementInstance.value.groupAAmount = amount.value)
        : (movementInstance.value.groupBAmount = amount.value);
      operationOptionSelected.value == OperationOptionTypes.BUY
        ? ((movementInstance.value.buyerUserId = shareholderId.value),
          (movementInstance.value.sellerUserId = destinatioShareholder))
        : ((movementInstance.value.sellerUserId = shareholderId.value),
          (movementInstance.value.buyerUserId = destinatioShareholder));

      loading.value = true;
      const response = await movementService.registerMovements(
        movementInstance.value
      );
      loading.value = false;

      if (!response.success) {
        errorMessage.value = (response.data as any)["detail"];
        if (errorMessage.value == dateWarningError.value) {
          forceRegister.value = true;
          Swal.fire(
            "¡Error!",
            "Algo salió mal, vuelva a recargar la página.",
            "error"
          );
        }
        return;
      }
      Swal.fire(
        "¡Listo!",
        "Solicitud de Movimiento  realizada con éxito",
        "success"
      );
      movementsStore.query(shareholderId.value);
      shareholderStore.query(shareholderId.value);
      movementOptionSelected.value = MovementOptionTypes.ACTION_B;
      amount.value = undefined;
      movementInstance.value.groupAAmount = undefined;
      movementInstance.value.groupBAmount = undefined;
      movementInstance.value.createdAt = undefined;
      movementInstance.value.forceUpdate = false;
      forceRegister.value = false;
      emit("update:visible", false);
    }

    return {
      loading,
      flow,
      forceRegister,
      hasError,
      errorMessage,
      submitNewMovement,
      isNullOrEmpty,
      date,
      movementOptionSelected,
      amount,
      typeActionFieldHasError,
      amountFieldHasError,
      formHasError,
      operationOptionSelected,
      MovementOptionTypes,
      OperationOptionTypes,
      shareholderSelected,
      shareholders,
      shareholderItems,
      shareholderValues,
    };
  },
});
