import {
  User,
  ChangePasswordData,
  UserFetchData,
  UserSession,
  ResetPasswordData,
} from "./types";
import { Response } from "../types";
import { getEnvConfig } from "@/configs/UrlConfig";
import {
  getResponse,
  keysToSnake,
  isNullOrEmpty,
  reformatDateISO8601,
} from "../utils";
import { useAuthStore } from "@/stores/auth";
import { AuthenticatedService } from "./AuthenticatedService";
type userResponse = {
  detail: string;
};
export class AuthService extends AuthenticatedService {
  async login(email: string, password: string): Promise<Response<UserSession>> {
    const url = `${getEnvConfig().apiURL}/auth/login_admin`;

    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);

    return getResponse(url, undefined, "POST", undefined, formData);
  }


  async forgotPassword(email: string): Promise<Response<UserSession>>{
    const url = `${getEnvConfig().apiURL}/auth/forgot-password`


    return getResponse(url, undefined, 'POST', {email:email})
}
async forgotPasswordReturn(data: object): Promise<Response<UserSession>>{
    const url = `${getEnvConfig().apiURL}/auth/reset-password`

    return getResponse(url, undefined, 'POST', data)
}
  async getPerfil(token: string): Promise<Response<User>> {
    const url = `${getEnvConfig().apiURL}/auth/me`;

    return getResponse(url, token);
  }

  async editUser(user: UserFetchData): Promise<Response<User | userResponse>> {
    const url = `${getEnvConfig().apiURL}/admin/user/${user.userId}/update`;
    console.log({ url, user });
    return getResponse(url, this.token, "PUT", keysToSnake(user), undefined);
  }
  async editShareholder(user: UserFetchData): Promise<Response<User | userResponse>> {
    const url = `${getEnvConfig().apiURL}/admin/shareholders/${user.userId}/account/`;
    console.log({ url, user });
    return getResponse(url, this.token, "PUT", keysToSnake(user), undefined);
  }

  async changePassword(
    Password: ChangePasswordData
  ): Promise<Response<ChangePasswordData>> {
    const url = `${getEnvConfig().apiURL}/auth/change-password`;
    return getResponse(
      url,
      this.token,
      "POST",
      keysToSnake(Password),
      undefined
    );
  }
  async changeByAdminPassword(
    Password: ChangePasswordData
  ): Promise<Response<ChangePasswordData>> {
    const url = `${getEnvConfig().apiURL}auth/change-password-admin `;
    return getResponse(
      url,
      this.token,
      "POST",
      keysToSnake(Password),
      undefined
    );
  }

  async logout(token: string): Promise<Response<any>> {
    const url = `${getEnvConfig().apiURL}/auth/logout`;

    return getResponse(url, token);
  }

  async resetPassword(passData: ResetPasswordData): Promise<Response<any>> {
    const url = `${getEnvConfig().apiURL}/auth/reset-password`;
    return getResponse(url, undefined, "POST", keysToSnake(passData));
  }
  async resetPasswordByEmail(recoveryEmail: string): Promise<Response<any>> {
    const url = `${getEnvConfig().apiURL}/auth/forgot-password?type=2`;
    const body = {
      email: recoveryEmail,
    };
    console.log({ url });
    console.log(body);
    return getResponse(url, undefined, "POST", keysToSnake(body));
  }

  /**
   * Valida que la sesion del usuario es valida.
   * @returns Retorna True si la sesión es valida y False en caso contrario
   */
  async hasValidUserSession() {
    const authStore = useAuthStore();
    if (authStore.token) {
      const response = await this.getPerfil(authStore.token);
      if (response.success) {
        authStore.setUser(response.data);
        return true;
      } else {
        authStore.logout();
        return false;
      }
    }
    return false;
  }

  async registerShareholder(
    token: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    rut: string,
    address: string,
    bankName: string,
    accountType: string,
    accountNumber: string,
    accountOwnerName: string,
    accountOwnerRut: string,
    accountEmail: string,
    businessName: string | null,
    businessAddress: string | null,
    businessRut: string | null,
    writingDate: Date | null,
    notary: string | null,
    repertoireNumber: string | null,
    password: string,
    isFemale: boolean,
    county: string,
    region: string,
    investorNumberA: number,
    investorNumberB: number
  ): Promise<Response<User | userResponse>> {
    const url = `${getEnvConfig().apiURL}/admin/user_accounts/create`;

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      rut: rut,
      address: address,
      business_name: !isNullOrEmpty(businessName) ? businessName : null,
      business_address: !isNullOrEmpty(businessAddress)
        ? businessAddress
        : null,
      business_rut: !isNullOrEmpty(businessRut) ? businessRut : null,
      writing_date: writingDate
        ? reformatDateISO8601(writingDate, false)
        : null,
      notary: !isNullOrEmpty(notary) ? notary : null,
      repertoire_number: !isNullOrEmpty(repertoireNumber)
        ? repertoireNumber
        : null,
      bank_name: bankName,
      account_type: accountType,
      account_number: accountNumber,
      account_owner_name: accountOwnerName,
      account_owner_rut: accountOwnerRut,
      account_email: accountEmail,
      password: password,
      client_is_female: isFemale,
      county,
      region,
      investor_number_a: investorNumberA,
      investor_number_b: investorNumberB,
      total_shares_a: 0,
      total_shares_b:0,
      is_selected:0,
      is_deleted:false
    };

    return getResponse(url, token, "POST", data);
  }
  async registerUser(
    token: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    rut: string,
    address: string,
    bankName: string,
    accountType: string,
    accountNumber: string,
    accountOwnerName: string,
    accountOwnerRut: string,
    accountEmail: string,
    businessName: string | null,
    businessAddress: string | null,
    businessRut: string | null,
    writingDate: Date | null,
    notary: string | null,
    repertoireNumber: string | null,
    password: string,
    isFemale: boolean,
    county: string,
    region: string,
    investorNumberA: number,
    investorNumberB: number,
  ): Promise<Response<User | userResponse>> {
    const url = `${getEnvConfig().apiURL}/admin/user/create`;
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      rut: rut,
      address: address,
      bank_name: bankName,
      password: password,
      client_is_female: isFemale,
      county,
      region,
      total_shares_a: 0,
      total_shares_b:0,
      is_selected:0,
      is_deleted:false
    };

    return getResponse(url, token, "POST", data);
  }
}
