
import { ref, toRef } from "@vue/reactivity";
import { defineComponent, PropType } from "vue";
import { VueDatePicker } from "@vuepic/vue-datepicker";
import { fixedLenght } from "@/services/utils";
import { reformatDateDayMonth } from "@/services/utils";

export default defineComponent({
  props: {
    array: {
      type: Array as PropType<string[] | null>,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      required: false,
    },
    isAcopled: {
      type: Boolean,
      required: false,
      default: false,
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["add", "drop"],
  setup(props, { emit }) {
    // State
    const datesLocalRef = toRef(props, "array");
    const item = ref("");
    const registerForm = ref({
      items: ["USER_item"],
    });
    const flow = ref<VueDatePicker["flow"]>(["month", "calendar"]);

    function formatDate(date: Date): string {
      const day = date.getDate();
      const month = date.getMonth() + 1;

      return `${fixedLenght(month, 2)} - ${fixedLenght(day, 2)}`;
    }

    /**
     * Reformatea la fecha de un formato "MM - DD" al nombre del mes con el día.
     * Por ejemplo "01 - 31" => "Enero 31"
     * @param date Fecha en formato string "MM - DD"
     */
    function formatDateWithWords(date: string) {
      // Preparando formato de la fecha a uno estándar
      const [month, day] = date.split(" - ");
      date = `2022-${month}-${day}`;

      // Generando reformateo
      return reformatDateDayMonth(date);
    }

    function addItem(itemName: Date) {
      emit("add", formatDate(itemName));
      item.value = "";
    }

    function removeItem(itemIndex: number) {
      emit("drop", itemIndex);
    }

    function createUser() {
      alert(JSON.stringify(registerForm.value));
    }

    //Export State
    return {
      item,
      addItem,
      datesLocalRef,
      registerForm,
      flow,
      removeItem,
      createUser,
      formatDateWithWords,
    };
  },
});
