
import { defineComponent, ref } from "vue";

export default defineComponent({
  emits: ["uploadFile"],
  setup(prop, { emit }) {
    const activeDropzone = ref<boolean>(false);
    function switcActivationDropzone() {
      activeDropzone.value = !activeDropzone.value;
    }
    function inputFileHandler(event: Event | DragEvent, isDrag = false) {
      let target: HTMLInputElement | DragEvent;
      let file: File | undefined;
      if (isDrag) {
        activeDropzone.value = false;
        target = event as DragEvent;
        file = target.dataTransfer ? target.dataTransfer?.files[0] : undefined;
      } else {
        target = event.target as HTMLInputElement;
        file = target.files ? target.files[0] : undefined;
      }
      if (file == undefined) {
        return false;
      }
      emit("uploadFile", file);

      // restringir si ya tiene un contrato firmado ??
    }
    return { activeDropzone, switcActivationDropzone, inputFileHandler };
  },
});
