export interface UserSession {
  createdAt: string;
  id: number; // Identificador de la sesion
  userId: number; // Identificador del usuario
  token: string; // Token de la sesión (guardar esto)
}
export class UserFetchData {
  constructor(
    public firstName: string,
    public lastName: string,
    public rut: string,
    public email: string,
    public phone: string | null,
    public address: string,
    public bankName: string,
    public accountType: string,
    public accountNumber: string,
    public accountOwnerName: string,
    public accountOwnerRut: string,
    public accountEmail: string,
    public userId: number,
    public county: string,
    public region: string,
    public investorNumberA: number | null,
    public investorNumberB: number | null,
    public client_is_female: number,
    public total_shares_a: number,
    public total_shares_b:number,
    
    public businessName:string |null,
    public businessAddress:string|null,
    public businessRut:string|null,
    public writingDate:string|null,
    public notary:string|null,
    public repertoireNumber:string|null,
  ) {}
}
export interface User {
  id: number;
  isAdmin: boolean;
  isShareholder: boolean;
  investorNumberA: number | null;
  investorNumberB: number | null;
  totalSharesA: number;
  totalSharesB: number;

  // Información del usuario
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  rut: string;
  address: string;

  // Información de al empresa
  businessName: string | null;
  businessAddress: string | null;
  businessRut: string | null;

  // Información bancaria
  bankName: string;
  accountType: string;
  accountNumber: string;
  accountOwnerName: string;
  accountOwnerRut: string;
  accountEmail: string;

  //Información personería
  writingDate: string | null;
  notary: string | null;
  repertoireNumber: string | null;
}

export interface UserResume {
  id: number;
  firstName: string;
  lastName: string;
  rut: string;
  investorNumberA: number;
  investorNumberB: number;
  totalSharesA: number;
  totalSharesB: number;

  // Información de al empresa
  businessName: string | null;
  businessAddress: string | null;
  businessRut: string | null;
}

export const bankNames = [
  "Banco Bice",
  "BBVA",
  "Banco Consorcio",
  "Banco De Chile - Edwards Citi",
  "Banco Del Desarrollo",
  "Banco Estado",
  "Banco Falabella",
  "Banco Internacional",
  "Banco Itaú",
  "Banco Paris",
  "Banco Ripley",
  "Banco Santander - Banefe",
  "Banco Security",
  "Bci - Tbanc - Nova",
  "Coopeuch",
  "Corpbanca",
  "HSBC Bank",
  "Los Héroes",
  "Mercado Pago Emisora S.A.",
  "Scotiabank",
  "TAPP Caja Los Andes",
  "TENPO Prepago",
  "Transbank",
];

export const bankAccountTypes = [
  "Cuenta Corriente",
  "Cuenta Vista",
  "Cuenta de Ahorro",
];

export class ChangePasswordData {
  constructor(
    public userId: number,
    public password: string,
    public oldPassword: string
  ) {}
}
export class ResetPasswordData {
  constructor(
    public newPassword: string,
    public confirmPassword: string,
    public token: string
  ) {}
}
