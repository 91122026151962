
import { defineComponent, ref} from 'vue'

export default defineComponent({
  props: {
    actived: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:actived'],
  setup(props, { emit }) {
    const handleClick = () => {
      emit('update:actived', !props.actived)
    }

    return {
      handleClick
    }
  }
})
