
import { defineComponent, Ref, ref, computed } from "vue";
import UsersTable from "@/components/organisms/UsersTable.vue";

import {
  TotalCapitalMetric,
  TotalShareholdersMetric,
  TotalPaidMetric,
  TotalSubsMetric,
} from "@/services/metrics/types";
import { toBadgeFormat } from "@/services/utils";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    UsersTable,
  },
});
