export interface reqContractSchema {
  readonly movementId: number | undefined;
  readonly firstWitnessId: number | undefined;
  readonly secondWitnessId: number | undefined;
}
export type document = File;

export class ContractReq implements reqContractSchema {
  constructor(
    public movementId: number | undefined,
    public firstWitnessId: number | undefined,
    public secondWitnessId: number | undefined
  ) {}
}
