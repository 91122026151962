import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse, keysToSnake } from "../utils";
import { Response } from "../types";
import { Parameter } from "./typer";

export class ParameterService extends AuthenticatedService {
  
  async getParameters(): Promise<Response<Parameter>> {
    const url = `${getEnvConfig().apiURL}/parameters/`;

    return getResponse(url, this.token, "GET", undefined, undefined, Parameter);
  }

  async editParameters({
    id,
    stockSaleWindows,
    minMonhtsToSellShares,
    minShares,
    ufSharesPrice,
    totalShares,
    dividendPaymentPeriod,
  }: {
    id?: number | undefined;
    stockSaleWindows?: string[][] | undefined;
    minMonhtsToSellShares?: number | undefined;
    minShares?: number | undefined;
    ufSharesPrice?: number | undefined;
    totalShares?: number | undefined;
    dividendPaymentPeriod?: string[] | undefined;
  }): Promise<Response<Parameter>> {
    const body = keysToSnake({
      stockSaleWindows,
      minMonhtsToSellShares,
      minShares,
      ufSharesPrice,
      totalShares,
      dividendPaymentPeriod,
    });
    const url = `${getEnvConfig().apiURL}/parameters/edit-parameters/`;

    return getResponse(url, this.token, "PATCH", body, undefined);
  }
}
