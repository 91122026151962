
import { computed, defineComponent, ref } from "vue";
// import Selector from "@/components/atoms/Selector.vue";
import SelectorHtmlBased from "@/components/atoms/SelectorHtmlBased.vue";
import { DividendsFilter } from "@/services/dividends/types";
import { ShareholderService } from "@/services/shareholder/ShareholderService";
import { ShareholderResume } from "@/services/shareholder/types";
import Datepicker from "@vuepic/vue-datepicker";

export default defineComponent({
  components: {
    // Selector,
    Datepicker,
    SelectorHtmlBased,
  },
  emits: ["submitFilterData"],
  setup(props, { emit }) {
    const shareholders = ref<ShareholderResume[]>([]);
    const shareholderService = new ShareholderService();
    const dividendsFilter = ref<DividendsFilter>(new DividendsFilter());
    const shareholderSelected = ref<string>("Todos");
    const shareholderItems = computed(() => {
      let shareholdersNames = shareholders.value.map((shareholder) => {
        return shareholder.identificationName;
      });
      return ["Todos", ...shareholdersNames];
    });
    const shareholderValues = computed(() => {
      let shareholderIds = shareholders.value.map((shareholder) => {
        return shareholder.id;
      });
      return ["-1", ...shareholderIds];
    });
    function submitHandler() {
      if (shareholderSelected.value != undefined) {
        console.log(shareholderSelected.value);
        parseInt(shareholderSelected.value) >= 0
          ? (dividendsFilter.value.shareholderId = parseInt(
              shareholderSelected.value
            ))
          : (dividendsFilter.value.shareholderId = undefined);
      }
      emit("submitFilterData", dividendsFilter.value);
    }

    async function getShareholders() {
      const response = await shareholderService.getShareholders();

      if (!response.success) {
        console.log("Ha ocurrido un error al intentar obtener los accionistas");
        shareholders.value = [];
        return;
      }
      shareholders.value = response.data;
    }

    getShareholders();

    return {
      submitHandler,
      shareholders,
      dividendsFilter,
      shareholderSelected,
      shareholderItems,
      shareholderValues,
    };
  },
});
