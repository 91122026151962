import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { fetchDownloadFileBodyRequired, keysToSnake } from "../utils";
import { ContractReq } from "./types";

export class ContractService extends AuthenticatedService {
  async createContract(contractReq: ContractReq): Promise<any> {
    const fileName = `contrato_mov_cod_${contractReq.movementId}.pdf`
    const url = `${getEnvConfig().apiURL}/movements/contracts/create`;
    return fetchDownloadFileBodyRequired(
      url,
      this.token,
      "POST",
      fileName,
      keysToSnake(contractReq)
    );
  }

}
