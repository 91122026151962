
import { defineComponent, PropType, Ref, ref } from "vue";
import InvestimentModal from "../organisms/InvestimentModal.vue";
import { Investment, InvestimentEnum } from "@/services/invest/types";
import PreviewInvestimentModal from "../organisms/PreviewInvestimentModal.vue";
import { InvestmentService } from "@/services/invest/investmentService";
import {
  toBadgeFormat,
  upercaseToTitle,
  toBadgeFormatUf,
} from "@/services/utils";
import EditInvestmentModal from "@/components/organisms/EditInvestmentModal.vue";
import { getEnvConfig } from "@/configs/UrlConfig";

export default defineComponent({
  props: {
    reloadInfo: {
      type: Function,
      required: false,
    },
    invest: {
      type: Object as PropType<Investment>,
      required: true,
    },
  },

  setup(props) {
    const keyIndexInvestModal = ref(0);
    const showModal = ref<boolean>(false);
    const showModalPreview = ref<boolean>(false);
    const lastRecordDaste: Ref<Date | null> = ref(null);
    const showModalEdit = ref<boolean>(false);
    const investmentService = new InvestmentService();
    async function getInvestiment() {
      let result = null;
      try {
        if (props.invest && props.invest.id) {
          result = await investmentService.getLastRecordDate(props.invest.id);
          lastRecordDaste.value = result.data;
        }
      } catch (error) {
        console.log(error);
      }
      return result;
    }

    getInvestiment();
    function getUrl(relativeUrl: string) {
      relativeUrl;
      let url = new URL(relativeUrl.substring(1), "http://localhost:8000");
      return url;
    }
    function handleAddRecord(e: Event) {
      e.stopPropagation();
      showModal.value = true;
      showModalEdit.value = false;
    }
    function handlePreview(e: Event) {
      e.stopPropagation();
      showModalPreview.value = true;
      showModalEdit.value = false;
    }
    function handleEdit() {
      showModalEdit.value = true;
      showModal.value = false;
      showModalPreview.value = false;
    }
    function reload() {
      getInvestiment();
      keyIndexInvestModal.value++;
    }
    function getNumberOfDays(start: Date) {
      const date1 = new Date(start);
      const date2 = new Date(Date.now());

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    }

    function formatSeparateLine(description: string) {
      return description.split("\n");
    }

    return {
      formatSeparateLine,
      toBadgeFormat,
      handleAddRecord,
      handlePreview,
      handleEdit,
      reload,
      getUrl,
      getNumberOfDays,
      upercaseToTitle,
      showModal,
      showModalEdit,
      showModalPreview,
      InvestimentEnum,
      keyIndexInvestModal,
      getEnvConfig,
      lastRecordDaste,
      toBadgeFormatUf,
    };
  },
  components: {
    InvestimentModal,
    PreviewInvestimentModal,
    EditInvestmentModal,
  },
});
