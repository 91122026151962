import { User } from "@/services/auth/types";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
    state: () => {
        const localStorageUser = localStorage.getItem("user")
        let user: User | null = null
        if(localStorageUser){
            user = JSON.parse(localStorageUser)
        }

        return {
            token: localStorage.getItem("token"),
            user: user
        }
    },

    actions: {
        setToken(token: string){
            this.token = token
            localStorage.setItem("token", token)
        },

        setUser(user: User){
            this.user = user
            localStorage.setItem("user", JSON.stringify(user))
        },

        logout(){
            this.token = null
            this.user = null
            localStorage.removeItem("token")
            localStorage.removeItem("user")
        }
    },

    getters: {
        userIsAuthenticated(state): boolean{
            return state.user != null
        }
    }
})

export type AuthStore = ReturnType<typeof useAuthStore>