import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full p-[4%] gap-5" }
const _hoisted_2 = { class: "flex text-lg font-semibold pb-1" }
const _hoisted_3 = {
  key: 0,
  class: "flex w-full"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewDividendForm = _resolveComponent("NewDividendForm")!
  const _component_FilterForm = _resolveComponent("FilterForm")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.formOptionSelected == _ctx.formOptionsType.newDividendForm
            ? "Crear Nuevo Dividendo"
            : "Filtro"), 1)
    ]),
    (_ctx.formOptionSelected == _ctx.formOptionsType.newDividendForm)
      ? (_openBlock(), _createElementBlock("article", _hoisted_3, [
          _createVNode(_component_NewDividendForm, {
            onSubmitNewDividendData: _ctx.submitNewDividentForm,
            isLoading: _ctx.loadingForm
          }, null, 8, ["onSubmitNewDividendData", "isLoading"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.formOptionSelected == _ctx.formOptionsType.filterForm)
      ? (_openBlock(), _createElementBlock("article", _hoisted_4, [
          _createVNode(_component_FilterForm, { onSubmitFilterData: _ctx.submitFilterForm }, null, 8, ["onSubmitFilterData"])
        ]))
      : _createCommentVNode("", true)
  ]))
}