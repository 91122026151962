
import NewModal from "@/components/atoms/NewModal.vue";
import TextField from "@/components/atoms/TextField.vue";
import { CreateContractData } from "@/services/movements/types";
import { isNullOrEmpty, validateRut } from "@/services/utils";
import { computed, defineComponent, Ref, ref, watch } from "vue";
import { Witness } from "@/services/documents/types";
import { WitnessService } from "@/services/documents/documentsService";
import { ContractReq } from "@/services/contract/types";
import { ContractService } from "@/services/contract/contractService";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      defaul: false,
    },
    movementId: {
      type: Number,
    },
  },
  components: {
    NewModal,
    TextField,
  },
  setup(props, { emit }) {
    const witnessService = new WitnessService();
    const contractService = new ContractService();
    const movementId = computed(() =>
      props.movementId ? props.movementId : -1
    );
    const firstWitnessCreationAvailable = ref<boolean>(false);
    const secondWitnessCreationAvailable = ref<boolean>(false);
    const isSubmitingWitnesses = ref<boolean>(false);
    const firstWitnessRut = ref<string>("");
    const secondWitnessRut = ref<string>("");
    const witnessesDataDefinitive = ref<Witness[]>([
      new Witness(undefined, "", undefined),
      new Witness(undefined, "", undefined),
    ]);
    const data = ref<CreateContractData>({
      movementId: movementId.value,
      firstWitnessId: 1,
      secondWitnessId: 3,
    });
    const userRut = ref<string>("");
    const firstWitnessNameHasError = computed(() => {
      return isNullOrEmpty(witnessesDataDefinitive.value[0].fullName);
    });
    const firstWitnessRutHasError = computed(() => {
      return (
        firstWitnessRut.value == secondWitnessRut.value ||
        isNullOrEmpty(firstWitnessRut.value) ||
        !validateRut(firstWitnessRut.value)
      );
    });
    const secondRutHasError = computed(() => {
      return (
        firstWitnessRut.value == secondWitnessRut.value ||
        isNullOrEmpty(secondWitnessRut.value) ||
        !validateRut(secondWitnessRut.value)
      );
    });
    const firstNameHasError = computed(() => {
      //TODO Si rut encontrado return false enel data definitivo
      return isNullOrEmpty(witnessesDataDefinitive.value[0].fullName);
    });
    const secondNameHasError = computed(() => {
      return isNullOrEmpty(witnessesDataDefinitive.value[1].fullName);
    });
    const formHasError = computed(() => {
      const requiredFields = [firstWitnessRut.value, secondWitnessRut.value];
      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }
      const formErrors = [
        firstWitnessRutHasError.value,
        firstWitnessNameHasError.value,
        firstNameHasError.value,
        secondNameHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }
      return false;
    });
    async function searchWitness(witnesRut: Witness["rut"]) {
      const res = await witnessService.searchWitness(witnesRut);
      return res;
    }
    function getWitnesByIndex(): Ref<string> {
      return firstWitnessRut;
    }
    async function createWitness(index: number, witnessRut: Ref<string>) {
      witnessesDataDefinitive.value[index].rut = witnessRut.value;
      const res = witnessService.createWitness(
        witnessesDataDefinitive.value[index]
      );
      return res;
    }
    async function submitContractForm() {
      if (firstWitnessCreationAvailable.value) {
        const res = await createWitness(0, firstWitnessRut);
        if (res.data) witnessesDataDefinitive.value[0] = res.data;
      }
      if (secondWitnessCreationAvailable.value) {
        const res = await createWitness(1, secondWitnessRut);
        if (res.data) witnessesDataDefinitive.value[1] = res.data;
      }
      isSubmitingWitnesses.value = true;
      let definitivesWitnessesIds = witnessesDataDefinitive.value.map(
        (witness) => witness.id
      );
      let contractReq = new ContractReq(
        movementId.value,
        definitivesWitnessesIds[0],
        definitivesWitnessesIds[1]
      );
      let contractRes = await contractService.createContract(contractReq);
      isSubmitingWitnesses.value = false;
      emit("update:visible", false);
      if (!contractRes) return false;
      return true;
    }
    watch(firstWitnessRut, async (currValue) => {
      if (!currValue) return false;
      if (currValue.length > 8) {
        if (!validateRut(currValue)) {
          witnessesDataDefinitive.value[0] = new Witness(
            undefined,
            "",
            undefined
          );
          firstWitnessCreationAvailable.value = false;
          return false;
        }
        const res = await searchWitness(currValue);
        if (!res.success) {
          firstWitnessCreationAvailable.value = true;
          return false;
        }
        witnessesDataDefinitive.value[0] = res.data;
      }
    });
    watch(secondWitnessRut, async (currValue) => {
      if (!validateRut(currValue) || firstWitnessRut.value == currValue) {
        witnessesDataDefinitive.value[1] = new Witness(
          undefined,
          "",
          undefined
        );
        secondWitnessCreationAvailable.value = false;
        return false;
      }

      const res = await searchWitness(currValue);
      if (!res.success) {
        secondWitnessCreationAvailable.value = true;
        return false;
      }
      witnessesDataDefinitive.value[1] = res.data;
    });

    return {
      submitContractForm,
      searchWitness,
      getWitnesByIndex,
      data,
      userRut,
      isSubmitingWitnesses,
      firstWitnessRut,
      secondWitnessRut,
      firstWitnessCreationAvailable,
      secondWitnessCreationAvailable,
      witnessesDataDefinitive,
      formHasError,
      firstNameHasError,
      secondNameHasError,
      secondRutHasError,
      firstWitnessRutHasError,
    };
  },
});
