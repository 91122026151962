import { Shareholder } from "@/services/shareholder/types";
import { defineStore } from "pinia";
import { UserService } from "@/services/user/UserService";

export const useUserStore = defineStore("user", {
  state: () => {
    let shareholder: Shareholder | undefined;
    const shareholderService = new UserService();

    return {
      shareholder,
      shareholderService,
    };
  },

  actions: {
    async query(id: number) {
      this.shareholderService
        .getShareholderDetail(id)
        .then(({ data }) => {
          this.shareholder = data
        });
    },
  },

  getters: {
    getData(state) {
      return state.shareholder;
    },
  },
});

export type shareholderStore = ReturnType<typeof useUserStore>;
