import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a79526e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shadow rounded-lg overflow-hidden flex flex-col bg-white h-full" }
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = {
  key: 0,
  class: "px-2 pt-[5px] font-bold text-left text-[16px]"
}
const _hoisted_4 = { class: "pb-4" }
const _hoisted_5 = { class: "flex flex-col justify-start items-start gap-4 px-4" }
const _hoisted_6 = { class: "font-bold text-[#9BB441] text-[28px]" }
const _hoisted_7 = { class: "font-light text-[12px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.metric), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.description), 1)
      ])
    ])
  ]))
}