import { renderSlot as _renderSlot, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "shadow rounded-lg flex flex-col bg-white h-full w-full" }
const _hoisted_2 = { class: "pb-4" }
const _hoisted_3 = { class: "flex bg-purple flex-col px-4 h-[70%] overflow-auto" }
const _hoisted_4 = { class: "table-auto w-full text-left" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = {
  key: 0,
  class: "w-[5px] right-0 top-1 z-10 py-2 cursor-pointer border-b pr-2"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "right-0 top-0 bg-white z-10 border-b"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "text-center items-center justify-center pr-2 py-1"
}
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 1,
  class: "top-0 mt-2 right-0 flex items-center justify-center z-10 py-1"
}
const _hoisted_13 = {
  key: 1,
  class: "flex bg-white w-full rounded-md button-container"
}
const _hoisted_14 = {
  key: 2,
  class: "flex gap-4 w-full h-[3rem] py-1"
}
const _hoisted_15 = { colspan: "10" }
const _hoisted_16 = {
  colspan: "10",
  scope: "col",
  class: "py-1"
}
const _hoisted_17 = {
  key: 0,
  class: "flex w-full items-center justify-center pt-8 gap-1"
}
const _hoisted_18 = { class: "flex items-center" }
const _hoisted_19 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "filter"),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _createElementVNode("thead", _hoisted_5, [
              _createElementVNode("tr", null, [
                (_ctx.isSelectable)
                  ? (_openBlock(), _createElementBlock("th", _hoisted_6, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectAll) = $event)),
                        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAll && _ctx.toggleAll(...args))),
                        class: "w-4 h-5 text-primary-green bg-gray-100 border-gray-300 rounded focus:ring-primary-green dark:focus:ring-primary-green dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      }, null, 544), [
                        [_vModelCheckbox, _ctx.selectAll]
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: column.id,
                    onClick: ($event: any) => (_ctx.$emit('sortBy', column.name)),
                    class: "right-0 top-0 z-10 bg-white w-max py-2 cursor-pointer border-b"
                  }, _toDisplayString(column.label), 9, _hoisted_7))
                }), 128)),
                (_ctx.showButtons)
                  ? (_openBlock(), _createElementBlock("th", _hoisted_8, " Acciones "))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: item.id
                }, [
                  _createElementVNode("tr", {
                    class: _normalizeClass(["cursor-pointer items-center hover:bg-primary-green/20 py-1", {
                    'bg-blue-200': item === _ctx.selectedRow + ` h-[${_ctx.gap}rem]`,
                  }]),
                    onClick: ($event: any) => (_ctx.$emit('onItemSelected', item))
                  }, [
                    (_ctx.isSelectable)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_10, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            value: item.id,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedRows) = $event)),
                            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateSelected && _ctx.updateSelected(...args))),
                            class: "w-4 h-5 text-primary-green bg-gray-100 border-gray-300 rounded focus:ring-primary-green dark:focus:ring-primary-green dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          }, null, 40, _hoisted_11), [
                            [_vModelCheckbox, _ctx.selectedRows]
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: column.id,
                        class: "py-1 px-8 lg:py-0 lg:px-0"
                      }, _toDisplayString(item[column.name]), 1))
                    }), 128)),
                    (_ctx.showButtons)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_12, [
                          (item.userRoleWorkers && item.userRoleWorkers != 3)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.buttons, (button) => {
                                return (_openBlock(), _createBlock(_component_mdicon, {
                                  class: _normalizeClass(["z-10", [
                          'mr-2 cursor-pointer px-2 py-1 rounded-md z-10',
                          button.className,
                        ]]),
                                  name: button.iconName,
                                  key: button.id,
                                  onClick: _withModifiers(($event: any) => (button.action(item)), ["stop"])
                                }, null, 8, ["name", "class", "onClick"]))
                              }), 128))
                            : _createCommentVNode("", true),
                          (!item.userRoleWorkers)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
                                  return (_openBlock(), _createBlock(_component_mdicon, {
                                    name: button.iconName,
                                    key: button.id,
                                    class: _normalizeClass([
                            'mr-1 ml-1 cursor-pointer px-1 py-1 rounded-md z-20',
                            button.className,
                          ]),
                                    onClick: _withModifiers(($event: any) => (button.action(item)), ["stop"])
                                  }, null, 8, ["name", "class", "onClick"]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showButtons)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_14, [
                          _renderSlot(_ctx.$slots, "actions", { item: item })
                        ]))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_9),
                  _createElementVNode("tr", _hoisted_15, [
                    _createElementVNode("td", _hoisted_16, [
                      _renderSlot(_ctx.$slots, "viewdata", { item: item })
                    ])
                  ])
                ], 64))
              }), 128))
            ])
          ]),
          (_ctx.matches)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createVNode(_component_mdicon, {
                  class: "rounded-full cursor-pointer bg-[#008b4e] text-white hover:bg-primary-green/50 focus:bg-primary-green/hover:bg-primary-green/50",
                  name: "chevron-double-left",
                  disabled: _ctx.currentPage === 1,
                  onClick: _ctx.firstPage
                }, null, 8, ["disabled", "onClick"]),
                _createVNode(_component_mdicon, {
                  class: "rounded-full cursor-pointer bg-[#008b4e] text-white hover:bg-primary-green/50 focus:bg-primary-green/hover:bg-primary-green/50",
                  name: "chevron-left",
                  disabled: _ctx.currentPage === 1,
                  onClick: _ctx.previousPage
                }, null, 8, ["disabled", "onClick"]),
                _createElementVNode("div", _hoisted_18, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visiblePages, (page) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: page,
                      class: _normalizeClass([
                  'cursor-pointer',
                  'px-3',
                  {
                    'bg-[#008B4E] text-white font-bold': _ctx.currentPage == page,
                    'bg-[#F0F0F0] text-[#969393] ': _ctx.currentPage !== page,
                  },
                ]),
                      onClick: ($event: any) => (_ctx.goToPage(page))
                    }, _toDisplayString(page), 11, _hoisted_19))
                  }), 128))
                ]),
                _createVNode(_component_mdicon, {
                  class: "rounded-full cursor-pointer bg-[#008b4e] text-white hover:bg-primary-green/50 focus:bg-primary-green/hover:bg-primary-green/50",
                  name: "chevron-right",
                  disabled: _ctx.currentPage === _ctx.totalPages,
                  onClick: _ctx.nextPage
                }, null, 8, ["disabled", "onClick"]),
                _createVNode(_component_mdicon, {
                  class: "rounded-full cursor-pointer bg-[#008b4e] text-white hover:bg-primary-green/50 focus:bg-primary-green/hover:bg-primary-green/50",
                  name: "chevron-double-right",
                  disabled: _ctx.currentPage !== _ctx.totalPages,
                  onClick: _ctx.lastPage
                }, null, 8, ["disabled", "onClick"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}