
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    metric: {
      type: [Number, String],
      required: true,
    },
    description: {
      type: String,
      rqeuired: false,
      default: "",
    },
  },
  setup() {
    return {};
  },
  components: {},
});
