
import { computed, defineComponent, Ref, ref, watch } from "vue";
import NewModal from "@/components/atoms/NewModal.vue";
import TextField from "@/components/atoms/TextField.vue";
import Selector from "@/components/atoms/Selector.vue";
import { AuthService } from "@/services/auth/AuthService";
import { bankAccountTypes, bankNames } from "@/services/auth/types";
import { useAuthStore } from "@/stores/auth";
import { isNullOrEmpty, makeRandomString, validateRut } from "@/services/utils";
import { isEnumMember } from "@babel/types";
import Swal from "sweetalert2";

export default defineComponent({
  components: { NewModal, TextField, Selector },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:visible", "reload"],
  setup(props, { emit }) {
    const isVisibleConfirmationModal = ref(false);
    const flow = ref(["year", "month", "calendar"]);

    type userResponse = {
      detail: string;
    };
    const authStore = useAuthStore();
    const authService = new AuthService();
    const editedTitularRut = ref<boolean>(false);
    const editedTitularEmail = ref<boolean>(false);
    const loading = ref(false);
    const hasError = ref(false);
    const textError = ref("");
    const errorMessage = ref("");

    const investorNumberA = ref<number>(0);
    const investorNumberB = ref<number>(0);

    // Información basica
    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const phone = ref("");
    const rut = ref("");
    const gender = ref("");

    const address = ref("");

    const bankName = ref("");
    const county = ref("");
    const region = ref("");
    const accountType = ref("");
    const accountNumber = ref("");
    const ownerName = ref("");
    const ownerRut = ref("");
    const bankEmail = ref("");

    const businessName = ref("");
    const companyAddress = ref("");
    const companyRut = ref("");
    const notary = ref("");
    const repertoireNumber = ref("");
    const writingDate: Ref<Date | null> = ref(null);

    const password = ref(makeRandomString(6));

    // Indica si el formulario tiene algun error de dato o valor requerido
    const formHasError = computed(() => {
      const requiredFields = [
        firstName.value,
        lastName.value,
        email.value,
        rut.value,
        address.value,
        bankName.value,
        accountType.value,
        accountNumber.value,
        ownerName.value,
        ownerRut.value,
        bankEmail.value,
        password.value,
      ];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [
        countyFieldHasError.value,
        regionFieldHasError.value,
        firstNameFieldHasError.value,
        lastNameFieldHasError.value,
        rutFieldHasError.value,
        emailFieldHasError.value,
        bankNameFieldHasError.value,
        accountTypeFieldError.value,
        accountFieldHasError.value,
        ownerFieldHasError.value,
        ownerRutFieldHasError.value,
        bankEmailFieldHasError.value,
        companyRutFieldHasError.value,
        addressFieldHasError.value,
        notaryHasError.value,
        writingDateHasError.value,
        repertoireNumberHasError.value,
        companyAddressHasError.value,
        county.value,
        region.value,
        genderFieldHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });

    const firstNameFieldHasError = computed(() => {
      return isNullOrEmpty(firstName.value);
    });

    const lastNameFieldHasError = computed(() => {
      return isNullOrEmpty(lastName.value);
    });

    const rutFieldHasError = computed(() => {
      return isNullOrEmpty(rut.value) || !validateRut(rut.value);
    });

    const emailFieldHasError = computed(() => {
      return isNullOrEmpty(email.value) || !email.value.includes("@");
    });

    const bankNameFieldHasError = computed(() => {
      return isNullOrEmpty(bankName.value);
    });

    const accountTypeFieldError = computed(() => {
      return isNullOrEmpty(accountType.value);
    });

    const accountFieldHasError = computed(() => {
      return isNullOrEmpty(accountNumber.value);
    });

    const ownerFieldHasError = computed(() => {
      return isNullOrEmpty(ownerName.value);
    });

    const ownerRutFieldHasError = computed(() => {
      return isNullOrEmpty(ownerRut.value) || !validateRut(ownerRut.value);
    });

    const bankEmailFieldHasError = computed(() => {
      return isNullOrEmpty(bankEmail.value) || !bankEmail.value.includes("@");
    });

    const companyRutFieldHasError = computed(() => {
      const isEmpty = isNullOrEmpty(companyRut.value);
      const isInvalidRut = !validateRut(companyRut.value);
      const isRequiredAndEmpty = businessDataAdded.value && isEmpty;
      return isRequiredAndEmpty || (businessDataAdded.value && isInvalidRut);
    });

    const businessNameHasError = computed(() => {
      const isEmpty = isNullOrEmpty(businessName.value);
      const isRequiredAndEmpty = businessDataAdded.value && isEmpty;
      return isRequiredAndEmpty;
    });

    const companyAddressHasError = computed(() => {
      const isEmpty = isNullOrEmpty(companyAddress.value);
      const isRequiredAndEmpty = businessDataAdded.value && isEmpty;
      return isRequiredAndEmpty;
    });

    const addressFieldHasError = computed(() => {
      return isNullOrEmpty(address.value);
    });

    const businessDataAdded = computed(() => {
      return (
        !isNullOrEmpty(companyRut.value) ||
        !isNullOrEmpty(companyAddress.value) ||
        !isNullOrEmpty(businessName.value)
      );
    });

    const writingDateHasError = computed(() => {
      return businessDataAdded.value && !writingDate.value;
    });

    const notaryHasError = computed(() => {
      return businessDataAdded.value && isNullOrEmpty(notary.value);
    });

    const repertoireNumberHasError = computed(() => {
      return businessDataAdded.value && isNullOrEmpty(repertoireNumber.value);
    });

    const genderFieldHasError = computed(() => {
      return isNullOrEmpty(gender.value);
    });
    const countyFieldHasError = computed(() => {
      return isNullOrEmpty(county.value);
    });
    const regionFieldHasError = computed(() => {
      return isNullOrEmpty(region.value);
    });
    watch(rut, (curr) => {
      if (!editedTitularRut.value) {
        ownerRut.value = curr;
      }
    });
    watch(email, (curr) => {
      if (!editedTitularEmail.value) {
        bankEmail.value = curr;
      }
    });

    /**
     * Envia una solicitud al backend para registrar al nuevo accionista
     */
    async function registerShareholder() {
      // TODO: Agregar modal de comprobación y aviso de que se enviara el email de registro
      if (!authStore.token) return;

      loading.value = true;
      hasError.value = false;
      const response = await authService.registerShareholder(
        authStore.token,
        firstName.value,
        lastName.value,
        email.value,
        phone.value,
        rut.value,
        address.value,
        bankName.value,
        accountType.value,
        accountNumber.value,
        ownerName.value,
        ownerRut.value,
        bankEmail.value,
        businessName.value,
        companyAddress.value,
        companyRut.value,
        writingDate.value,
        notary.value,
        repertoireNumber.value,
        password.value,
        gender.value == "Mujer",
        county.value,
        region.value,
        investorNumberA.value,
        investorNumberB.value
      );
      loading.value = false;

      if (!response.success) {
        let userResponseData = response.data as userResponse;
        textError.value = userResponseData.detail;
        hasError.value = true;

        return;
      }
      Swal.fire("¡Listo!", "El accionista se ha creado con éxito", "success");
      emit("reload");
      emit("update:visible", false);
    }

    return {
      loading,
      hasError,
      textError,
      errorMessage,
      registerShareholder,
      flow,

      firstName,
      lastName,
      rut,
      email,
      phone,
      address,
      gender,

      bankName,
      accountType,
      accountNumber,
      ownerName,
      ownerRut,
      bankEmail,

      businessName,
      companyAddress,
      companyRut,
      county,
      region,

      password,
      investorNumberA,
      investorNumberB,

      bankAccountTypes,
      bankNames,

      writingDate,
      notary,
      repertoireNumber,

      // Validadores de errores de los campos
      firstNameFieldHasError,
      lastNameFieldHasError,
      rutFieldHasError,
      emailFieldHasError,
      bankNameFieldHasError,
      accountTypeFieldError,
      accountFieldHasError,
      ownerFieldHasError,
      ownerRutFieldHasError,
      bankEmailFieldHasError,
      companyRutFieldHasError,
      businessNameHasError,
      companyAddressHasError,
      addressFieldHasError,
      businessDataAdded,
      writingDateHasError,
      repertoireNumberHasError,
      notaryHasError,
      genderFieldHasError,
      countyFieldHasError,
      regionFieldHasError,
      isVisibleConfirmationModal,

      // Validador resumen de errores del formulario
      formHasError,
    };
  },

  watch: {
    visible() {
      this.password = makeRandomString(6);
    },
  },
});
