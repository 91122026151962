import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex text-sm font-semibold pb-1"
}
const _hoisted_2 = { class: "flex flex-row rounded-lg from-slate-200/80 via-slate-200/70 to-slate-200/80 text-button-green items-center justify-start gap-3" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (index) => {
        return _createElementVNode("button", {
          key: index - 1,
          class: _normalizeClass(["p-[2%] m-[2%] text-sm", [
          _ctx.selectedOptionIndex == index - 1
            ? 'bg-[#9BB441] shadow-md text-white'
            : 'border-[#5E9bb441] border-2 text-[#5E9bb441]',
          _ctx.selectedOptionIndex == 0 ? 'rounded-lg' : 'rounded-lg',
        ]]),
          onClick: _withModifiers(($event: any) => (_ctx.handleSwitchingClick(index - 1)), ["stop"])
        }, _toDisplayString(_ctx.switchOptions[index - 1]), 11, _hoisted_3)
      }), 64))
    ])
  ]))
}