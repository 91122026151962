
/**
 * Importando recursos de tailwind. Si se borra esto, tailwind queda aislado
 * de la aplicación.
 */
import "./assets/tailwind.css";

import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { AuthService } from "./services/auth/AuthService";

import AdminTopbar from "./components/organisms/AdminTopbar.vue";

export default defineComponent({
  components: { AdminTopbar },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const showTopbar = computed(() => {
      return typeof route.name == "string" && !route.name.includes("auth");
    });

    const authService = new AuthService();
    async function checkSession() {
      if (route.name == "auth-forgot-password") {
        router.push({ name: "auth-forgot-password" });
        return;
      }
      if (route.name == "auth-forgot-password-return") {
        router.push({ name: "auth-forgot-password-return" });
        return;
      }
      /*const hasValidSession = await authService.hasValidUserSession();
      if (!hasValidSession) {
        router.push({ name: "auth-login" });
      }*/
    }
    checkSession();

    return {
      showTopbar,
    };
  },
});
