import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse, keysToSnake } from "../utils";
import { Witness } from "./types";
import { Response } from "../types";
export class WitnessService extends AuthenticatedService {
  async createWitness(witness: Witness): Promise<Response<Witness>> {
    const url = `${getEnvConfig().apiURL}/documents/witnesses/create`;
    return getResponse(
      url,
      this.token,
      "POST",
      keysToSnake(witness),
      undefined
    );
  }
  async searchWitness(witnessRut: Witness["rut"]) {
    const url = `${getEnvConfig().apiURL}/documents/witnesses/search`;
    const body = { rut: witnessRut };
    return getResponse(url, this.token, "POST", body, undefined);
  }
}
