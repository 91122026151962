
import { defineComponent } from "vue";
export default defineComponent( {
    props: {
        params: Object
    },
    setup(props){
        console.log(props.params)
    }
});
