

export class Account {
    id!: number;
    name!: string;
    accountNumber!: string | undefined;
    isSelected!:number

}
export type AccountCreateType = {
    readonly user_id: number | undefined,
    readonly bank_code: number,
    readonly bank_name: string,
    readonly account_type: string,
    readonly account_number: string,
    readonly account_owner_name: string,
    readonly account_owner_rut: string,
    readonly account_email: string,
  };
  export type AccountSelectedType = {
    readonly account_id: number | undefined;
  };
export class AccountSelectedClass {
    constructor(
      readonly account_id: AccountSelectedType["account_id"],
    ) { }
  }
export class AccountCreateClass {
constructor(
    readonly user_id: AccountCreateType["user_id"],
    readonly bank_code: AccountCreateType["bank_code"],
    readonly bank_name: AccountCreateType["bank_name"],
    readonly account_type: AccountCreateType["account_type"],
    readonly account_number: AccountCreateType["account_number"],
    readonly account_owner_name: AccountCreateType["account_owner_name"],
    readonly account_owner_rut: AccountCreateType["account_owner_rut"],
    readonly account_email: AccountCreateType["account_email"],
) { }
}
