
import { computed, defineComponent, ref, Ref, markRaw } from "vue";
import ShareholderDividendsTable from "@/components/organisms/ShareholderDividendsTable.vue";
import ShareholderSharesTable from "@/components/organisms/ShareholderSharesTable.vue";
import { DividendService } from "@/services/dividends/dividendService";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: { ShareholderSharesTable, ShareholderDividendsTable },
  setup() {
    const viewActive: Ref<number> = ref(1);

    const dividendService = new DividendService();

    const route = useRoute();

    function downloadExcel() {
      const shareholderId = +route.params.id;

      dividendService.downloadDividendsPaymentsList(shareholderId);
    }

    return {
      viewActive,
      downloadExcel,
    };
  },
});
