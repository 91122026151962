
import MectricCardTotal from "@/components/molecules/MetricCardTotal.vue";
import MectricCardShares from "@/components/molecules/MetricCardShares.vue";

import { defineComponent, Ref, ref, computed } from "vue";
import ShareholderTable from "@/components/organisms/ShareholdersTable.vue";
import { MetricService } from "@/services/metrics/MetricService";

import {
  TotalCapitalMetric,
  TotalShareholdersMetric,
  TotalPaidMetric,
  TotalSubsMetric,
} from "@/services/metrics/types";
import { toBadgeFormat } from "@/services/utils";

export default defineComponent({
  setup() {
    const metricService = new MetricService();
    const totalCapitalMetric: Ref<undefined | TotalCapitalMetric> = ref();
    const totalShareholdersMetric: Ref<undefined | TotalShareholdersMetric> =
      ref();
    const totalPaidMetric: Ref<undefined | TotalPaidMetric> = ref();
    const totalSubsMetric: Ref<undefined | TotalSubsMetric> = ref();
    const bothGroups = ref<number>();
    const totalPayDescription = computed(() => {
      if (totalCapitalMetric.value == undefined) {
        return "Cargando...";
      }

      const capitalUf = toBadgeFormat(totalCapitalMetric.value.capitalUf);
      return `De UF ${capitalUf}`;
    });

    const totalEmitedSharesDescription = computed(() => {
      if (totalCapitalMetric.value == undefined) {
        return "Cargando...";
      }

      const capitalShares = toBadgeFormat(
        totalCapitalMetric.value.capitalShares
      );
      return `De ${capitalShares} acciones emitidas`;
    });

    const totalShareholdersDescription = computed(() => {
      if (totalShareholdersMetric.value == undefined) {
        return "Cargando...";
      }
      let shareholdersA = totalShareholdersMetric.value.groupAShareholders;
      let shareholdersB = totalShareholdersMetric.value.groupBShareholders;

      return ` ${shareholdersA} en el grupo A y ${shareholdersB} en el grupo B `;
    });

    const totalShareholders = computed(() => {
      if (totalShareholdersMetric.value == undefined) {
        return "0";
      }

      const shareholders = toBadgeFormat(
        totalShareholdersMetric.value.totalShareholders
      );
      return shareholders;
    });

    const totalPaidDescription = computed(() => {
      if (totalSubsMetric.value == undefined) {
        return "0";
      }

      const capitalPaid = toBadgeFormat(totalSubsMetric.value.totalShares * 50);
      return `UF ${capitalPaid}`;
    });

    const totalSubs = computed(() => {
      if (totalSubsMetric.value == undefined) {
        return "0";
      }

      const totalShares = toBadgeFormat(totalSubsMetric.value.totalShares);
      return `${totalShares}`;
    });
    const totalSubsA = computed(() => {
      if (totalSubsMetric.value == undefined) {
        return "0";
      }

      const totalShares = toBadgeFormat(totalSubsMetric.value.totalSharesA);
      return `${totalShares}`;
    });
    const totalSubsB = computed(() => {
      if (totalSubsMetric.value == undefined) {
        return "0";
      }

      const totalShares = toBadgeFormat(totalSubsMetric.value.totalSharesB);
      return `${totalShares}`;
    });

    async function fetchMetrics() {
      const response = await metricService.getTotalCapital();

      if (!response.success) {
        console.log("Error al intentar obtener las métricas");
        return;
      }

      totalCapitalMetric.value = response.data;

      const response1 = await metricService.getTotalShareholders();

      if (!response1.success) {
        console.log("Error al intentar obtener las métricas");
        return;
      }

      totalShareholdersMetric.value = response1.data;

      const responseShareholder = await metricService.getTotalShareholders();

      if (!responseShareholder.success) {
        console.log("Error al intentar obtener las métricas");
        return;
      }

      totalShareholdersMetric.value = responseShareholder.data;

      const responPaid = await metricService.getPaidInCapital();

      if (!responPaid.success) {
        console.log("Error al intentar obtener las métricas");
        return;
      }

      totalPaidMetric.value = responPaid.data;

      const responseSubs = await metricService.getTotalSubs();

      if (!responseSubs.success) {
        console.log("Error al intentar obtener las métricas");
        return;
      }

      totalSubsMetric.value = responseSubs.data;
    }

    fetchMetrics();

    return {
      totalCapitalMetric,
      totalPayDescription,
      totalEmitedSharesDescription,
      totalShareholdersMetric,
      totalShareholdersDescription,
      totalShareholders,
      totalPaidMetric,
      totalPaidDescription,
      totalSubsMetric,
      totalSubs,
    };
  },
  components: {
    MectricCardTotal,
    ShareholderTable,
    MectricCardShares,
  },
});
