
import { InvestmentService } from "@/services/invest/investmentService";
import { InvestmentRequest, Record } from "@/services/invest/types";
import { defineComponent, toRef } from "vue";
import NewModal from "../atoms/NewModal.vue";
import FormInvestiment from "../molecules/FormInvestiment.vue";
import FormRecord from "../molecules/FormRecord.vue";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:visible", "reload"],
  setup(props, { emit }) {
    const idRef = toRef(props, "id");
    const investmentService = new InvestmentService();
    async function registerInvestiment(data: InvestmentRequest) {
      let res = null;
      try {
        res = await investmentService.createInvestiment(data);
        if (res.success) {
          alert("Inversión agregada exitosamente");
          emit("reload");
          emit("update:visible", false);
        }
      } catch (error) {
        console.log(error);
      }
      return data;
    }
    async function registerRecord(data: Record) {
      let res = null;
      try {
        res = await investmentService.createRecord(data);
        if (res.success) {
          alert("Record agregada exitosamente");
          emit("reload");
          emit("update:visible", false);
        }
      } catch (error) {
        console.log(error);
      }
      return res;
    }
    return { registerInvestiment, registerRecord, idRef };
  },
  components: { NewModal, FormInvestiment, FormRecord },
});
