import { User } from "../auth/types";

export enum contractOptions {
  "generate" = "Generar Contrato",
  "upload" = "Cargar Contrato",
  "signed" = "Copia Firmada",
}

export class CreateContractData {
  constructor(
    readonly movementId: number,
    readonly firstWitnessId: number,
    readonly secondWitnessId: number
  ) {}
}

export class CreateWitnessData {
  constructor(readonly rut: string, readonly fullName: string) {}
}
export class UploadContractData {
  constructor(readonly movementId: number, readonly signedContract: File) {}
}
export enum MovementOptionTypes {
  ACTION_A = "Acción A",
  ACTION_B = "Acción B",
}
export enum OperationOptionTypes {
  SELL = "Venta",
  BUY = "Compra",
}
export class ShareholderBaseMovement {
  constructor(
    public buyerUserId: number | undefined = undefined,
    public sellerUserId: number | undefined = undefined,
    public groupAAmount: number | undefined = undefined,
    public groupBAmount: number | undefined = undefined,
    public createdAt: string | undefined = undefined,
    public forceUpdate: boolean | undefined = false
  ) {}
}

export class ShareholderMovement extends ShareholderBaseMovement {
  constructor(
    readonly id: number,
    readonly createdAt: string,
    readonly buyerUserId: number,
    readonly buyerUser: User,
    readonly sellerUser: User,
    readonly sellerUserId: number,
    readonly groupAAmount: number,
    readonly groupBAmount: number,
    readonly buyerGroupAFinalAmount: number,
    readonly buyerGroupBFinalAmount: number,
    readonly sellerGroupAFinalAmount: number,
    public BFinalAmount: number = 0,
    public AFinalAmount: number = 0,
    readonly sellerGroupBFinalAmount: number,
    readonly isContractSigned: boolean
  ) {
    super(buyerUserId, sellerUserId, groupAAmount, groupBAmount, createdAt);
  }
}

export class ShareholderDividents {
  constructor(
    readonly id: number,
    readonly date: Date,
    readonly amount: number,
    readonly status: string,
    readonly operationNumber: number,
    readonly destinyAcount: string
  ) {}
}

export class ShareholdersShares {
  constructor(
    readonly movements: ShareholderMovement[],
    readonly dividents: ShareholderDividents[]
  ) {}
}
