import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c17dbc98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selector w-full" }
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = {
  key: 0,
  class: "text-red-500 ml-auto flex place-items-center"
}
const _hoisted_4 = { class: "inline-block relative w-full" }
const _hoisted_5 = { class: "flex items-center relative select-none h-[38px]" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["flex text-sm font-normal pb-2", { 'text-red-500': _ctx.hasError, 'text-gray-700': !_ctx.hasError }]),
          for: "selection"
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
          (_ctx.hasError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Popper, {
                  arrow: "",
                  class: "font-normal",
                  placement: "auto",
                  hover: "",
                  content: _ctx.errorMessage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_mdicon, {
                      name: "error",
                      size: "16"
                    })
                  ]),
                  _: 1
                }, 8, ["content"])
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.showSelectionList)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "absolute w-64 h-96 z-50 top-10 bg-white overflow-auto",
              tabindex: "0",
              onFocusout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSelectionList = false))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objectOfItemsFiltered, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  onClick: ($event: any) => (_ctx.selectItem(item)),
                  class: "cursor-pointer hover:bg-gray-200 p-2"
                }, _toDisplayString(item.item), 9, _hoisted_6))
              }), 128))
            ], 32))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.itemFilterInput) = $event)),
          type: "text",
          list: "items",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.inputClickHandler && _ctx.inputClickHandler(...args))),
          class: "appearance-none border rounded-xl w-full py-1 px-2 leading-tight focus:outline-none cursor-pointer"
        }, null, 512), [
          [_vModelText, _ctx.itemFilterInput]
        ]),
        _createTextVNode(_toDisplayString() + " ", 1),
        _createVNode(_component_mdicon, {
          name: "chevron-down",
          size: "16",
          class: "absolute right-2 pointer-events-none"
        })
      ])
    ])
  ]))
}