import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-603b62c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col gap-6" }
const _hoisted_2 = { class: "flex flex-col lg:flex-row justify-between lg:h-40 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MectricCardTotal = _resolveComponent("MectricCardTotal")!
  const _component_MectricCardShares = _resolveComponent("MectricCardShares")!
  const _component_ShareholderTable = _resolveComponent("ShareholderTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MectricCardTotal, {
        class: "min-w-[300px] w-ful",
        title: "Capital Pagado",
        description: _ctx.totalPayDescription,
        metric: _ctx.totalPaidDescription
      }, null, 8, ["description", "metric"]),
      (_ctx.totalSubsMetric)
        ? (_openBlock(), _createBlock(_component_MectricCardShares, {
            key: 0,
            class: "min-w-[300px] w-full",
            title: "Acciones suscritas",
            description: _ctx.totalEmitedSharesDescription,
            metric: _ctx.totalSubs,
            seriea: _ctx.totalSubsMetric?.totalSharesA,
            serieb: _ctx.totalSubsMetric?.totalSharesB
          }, null, 8, ["description", "metric", "seriea", "serieb"]))
        : _createCommentVNode("", true),
      (_ctx.totalShareholdersMetric)
        ? (_openBlock(), _createBlock(_component_MectricCardShares, {
            key: 1,
            class: "min-w-[300px] w-full",
            title: "Nº de accionistas",
            metric: _ctx.totalShareholders,
            seriea: _ctx.totalShareholdersMetric?.groupAShareholders,
            serieb: _ctx.totalShareholdersMetric?.groupBShareholders
          }, null, 8, ["metric", "seriea", "serieb"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ShareholderTable)
  ]))
}