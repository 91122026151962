
import { computed, watch, defineComponent, ref, onMounted } from "vue";
import TextField from "@/components/atoms/TextField.vue";
import Selector from "@/components/atoms/Selector.vue";
import { bankNames, bankAccountTypes } from "@/services/auth/types";
import { useAuthStore } from "@/stores/auth";
import { AccountService } from "@/services/account/AccountService";
import NewModal from "@/components/atoms/NewModal.vue";
import { AuthService } from "@/services/auth/AuthService";
import { reformatDate, reformatDateISO8601 } from "@/services/utils";
import { accountStore } from "@/stores/account";
import { shareMovementStore } from "@/stores/movements";
import { useRoute } from "vue-router";
import NewAccountModal from "@/components/organisms/NewAccountModal.vue";
import Swal from "sweetalert2";

export default defineComponent({
  components: { NewAccountModal, NewModal },

  setup() {
    const showNewAccountModal = ref(false);
    const movementsStore = shareMovementStore();
    const authStore = useAuthStore();
    const changed = ref<boolean>(false);
    const shareholder = computed(() => authStore.user);
    const accountStr = accountStore();

    const accountService = new AccountService();

    const loading = ref(false);

    const route = useRoute();

    const selectedDeleteAccount = ref<number>(0);
    const accountOpen = ref<number>(0);
    const visible = ref<boolean>(false);
    const visibleDelete = ref<boolean>(false);

    const visibleView = ref<boolean>(false);
    const nameAccount = ref<string>("");
    const nameAccountFieldHasError = computed(() => {
      return nameAccount.value.length == 0;
    });

    const activeCount = computed(() => accountStr.activeAccount);
    const accounts = computed(() => accountStr.account);

    watch(
      shareholder,
      (value, oldValue) => {
        if (oldValue != undefined) {
          changed.value = true;
        }
      },
      { deep: true }
    );
    onMounted(() => {
      getAccountServices();
    });

    const authService = new AuthService();

    async function setWritingDate(date: string) {
      if (shareholder.value == undefined) return;
      shareholder.value.writingDate = reformatDateISO8601(new Date(date));
    }

    const openModalNewAccount = () => {
      visible.value = true;
    };
    const submitNewAccount = () => {
      visible.value = false;
    };

    const openModalDeleteAccount = (id: number) => {
      visibleDelete.value = true;
      selectedDeleteAccount.value = id;
    };
    const submitDeleteAccount = () => {
      visibleDelete.value = false;
      selectedDeleteAccount.value = 0;
    };

    const openModalViewAccount = (id: number) => {
      visibleView.value = true;
      accountOpen.value = id;
    };
    const closeEditAccount = () => {
      visibleView.value = false;
    };

    async function setAccount(id: number) {
      accountStr.setActiveAccount(id);
    }

    async function getAccountServices() {
      if (+route.params.id) {
        accountStr.query(+route.params.id);
      }
    }
    async function deleteAccionsta() {
      loading.value = true;

      try {
        const response = await accountService.deleteAccount(
          selectedDeleteAccount.value
        );
        if (response.success == true) {
          loading.value = false;
          submitDeleteAccount;
          getAccountServices();
          Swal.fire(
            "¡Listo!",
            "El accionista fue desligado con éxito",
            "success"
          );
        } else {
          Swal.fire(
            "¡Error!",
            "Lo sentimos, hubo un error, intente más tarde",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
      }

      submitDeleteAccount();
    }

    return {
      openModalDeleteAccount,
      submitDeleteAccount,
      visibleDelete,
      showNewAccountModal,
      nameAccount,
      bankNames,
      bankAccountTypes,
      shareholder,
      changed,
      accounts,
      reformatDate,
      setWritingDate,
      openModalNewAccount,
      visible,
      visibleView,
      nameAccountFieldHasError,
      submitNewAccount,
      openModalViewAccount,
      accountOpen,
      setAccount,
      activeCount,
      deleteAccionsta,
    };
  },
});
