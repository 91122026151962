
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    model: {
      type: [Array, String],
      required: true,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    icons: {
      type: Array,
      required: false,
    },
    values: {
      type: Array,
      required: false,
    },
    nullAlowed: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      required: false,
    },
    multiPlaceholder: {
      type: String,
      required: false,
      default: "Estados",
    },
    label: {
      type: String,
      required: false,
    },

    onTop: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  emits: ["update:model"],

  setup(props, { emit }) {
    const isActive = ref(false);
    const errorMessageStyle = computed(() =>
      props.hasError ? "" : "visibility: hidden"
    );

    const placeholder = computed(() => {
      if (__isMultiselection.value)
        return props.model.length > 0
          ? `${props.model.length} ${props.multiPlaceholder}`
          : "";
      else {
        if (props.values) {
          const index = props.values.indexOf(props.model);
          return props.items[index];
        }
        return props.model;
      }
    });

    const __isMultiselection = computed(() => props.model instanceof Array);

    function __getRealItem(item: string): any {
      return props.values ? props.values[props.items.indexOf(item)] : item;
    }

    function __isSelected(item: string): boolean {
      if (__isMultiselection.value) return props.model.includes(item);
      return props.model == item;
    }

    function __unselect(item: string) {
      if (__isMultiselection.value) {
        const index = props.model.indexOf(item, 0);
        if (index > -1) {
          const newModel = [...props.model];
          newModel.splice(index, 1);
          emit("update:model", newModel);
        }
      } else if (props.nullAlowed) {
        emit("update:model", "");
      }
    }

    function __select(item: string) {
      if (__isMultiselection.value) {
        const newModel = [...props.model];
        newModel.push(item);
        emit("update:model", newModel);
      } else {
        emit("update:model", item);
      }
    }

    function getItemExtraClass(item: string): string {
      const realItem = __getRealItem(item);
      if (__isSelected(realItem)) {
        return "text-primary-400 font-bold bg-primary-50";
      }
      return "";
    }

    function switchSelection(item: string) {
      const realItem: any = __getRealItem(item);
      if (__isSelected(realItem)) __unselect(realItem);
      else __select(realItem);

      // Ocultando menu si es que es seleccion simple
      if (!__isMultiselection.value) isActive.value = false;
    }

    return {
      isActive,
      errorMessageStyle,
      placeholder,

      getItemExtraClass,
      switchSelection,
    };
  },
});
