
import { ref, defineComponent, watch, computed, PropType } from 'vue';

export default defineComponent({
  name: 'Selector',
  props: {
    items: {
      type: Array as PropType<Array<any>>,
      default: () => [],
      required: true
    },
    values: {
      type: Array as PropType<Array<any>>,
      default: () => [],
      required: true
    },
    selectedItem: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    itsDisabled: {
      type: Boolean,
      default: false
    },
    placeHolder: {
      type: String,
      default: 'Seleccione una Opción'
    },
    backGround: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    fountText: {
      type: String,
      default: ''
    },
  },
  emits: ['update:model'],
  setup(props, { emit }) {
    const selectedItems = ref(props.selectedItem);
    const showSelectionList = ref(false);
    const itemFilterInput = ref<string>('')
    
    const objectOfItems = computed(() => {
      return props.items.map((item, index) => {
        return { 'item': item, 'value': props.values[index] }
      })
    })

    const objectOfItemsFiltered = computed(() => {
      if (itemFilterInput.value == '') {
        return objectOfItems.value
      }
      else {
       return objectOfItems.value.filter((object)=>{
        return object.item.toLowerCase().includes(itemFilterInput.value.toLowerCase())
       })
      //  props.items.filter((item) => {
      //     return item.toLowerCase().includes(itemFilterInput.value.toLowerCase())
      //   }).map((item, index) => {
      //     return { 'item': item, 'value': props.values[index] }
      //   })

      }
    })
    const itemsFiltered = computed(() => {
      if (itemFilterInput.value == '') {
        return props.items
      }
      else {
        return props.items.filter((item) => {
          return item.toLowerCase().includes(itemFilterInput.value.toLowerCase())
        })
      }


    })
    // watch((itemFilterInput), (current, old) => {
    //   itemsFiltered.value = props.items
    //   if (current != old || current != '') {
    //     console.log(current)
    //     itemsFiltered.value = itemsFiltered.value.filter((value) => { return value.includes(current) })
    //   }
    // })
    function onBlur() {
      setTimeout(() => showSelectionList.value = false, 200)
    }
    function selectItem(item: any) {
      console.log(item)
      itemFilterInput.value = item.item
      selectedItems.value = item.value
      showSelectionList.value = false
      emit('update:model', item.value);
    }
    function inputClickHandler() {
      showSelectionList.value = !showSelectionList.value
      if(!selectedItems.value) return
      itemFilterInput.value = ''
      selectedItems.value = -1
    }
    function getIdByName(itemName: string) {
      // TODO usar reducer 

      let subArray = props.items.map((item, index) => {
        if (item == itemName) {
          return index;
        }
      })
      console.log(subArray)
      return 3
    }
    function onChange(event: any) {
      console.log(event.target)
      selectedItems.value = event.target.value;
      emit('update:model', selectedItems.value);
    }

    return {
      onBlur,
      inputClickHandler,
      selectItem,
      selectedItems,
      onChange,
      itemsFiltered,
      objectOfItemsFiltered,
      itemFilterInput,
      showSelectionList
    };
  }
});
