import { getEnvConfig } from "@/configs/UrlConfig";
import { getResponse, keysToSnake, fetchDownloadFile,getResponsePagination } from "../utils";
import { Response } from "../types";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { Shareholder, ShareholderResume } from "./types";

export class UserService extends AuthenticatedService {
  async getShareholders(page = 0,order = '',typeOrder = false,search=''): Promise<Response<ShareholderResume[]>> {
    let url = `${getEnvConfig().apiURL}/admin/shareholders?`;
    if(page > 0){
      url += `page=${page}`;
    }
    if(order != '' ){
      url += `&order_field=${order}`;
      url += `&order=${typeOrder == false ? 'DESC': 'ASC'}`;
    }
    if(search!=''){
      url += `&search=${search}`;
    }

    return getResponsePagination(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      ShareholderResume,
      true
    );
  }
  async deleteUser(shareholderId: number): Promise<Response<any>> {
    const url = `${getEnvConfig().apiURL}/admin/user/${shareholderId}/delete`;
    return getResponse(
      url,
      this.token,
      "DELETE",
      undefined,
      undefined,
    );
  }

  async getShareholderDetail(
    shareholderId: number
  ): Promise<Response<Shareholder>> {
    const url = `${getEnvConfig().apiURL}/admin/user/${shareholderId}/get`;

    return getResponse(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      Shareholder
    );
  }

  async getShareholderResume(
    shareholderId: number
  ): Promise<Response<ShareholderResume>> {
    const url = `${
      getEnvConfig().apiURL
    }/admin/user/${shareholderId}/get`;

    return getResponse(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      ShareholderResume
    );
  }

  async downloadExcel(dividendId: number
    ): Promise<void> {
    const url = `${getEnvConfig().apiURL}/dividends/payments/all/excel/?dividend_id=${dividendId}`;

    return fetchDownloadFile(url, "dividendos-por-id.xlsx");
  }
}
