
import InvestCard from "@/components/molecules/InvestCard.vue";
import InvestimentModal from "@/components/organisms/InvestimentModal.vue";
import { InvestmentService } from "@/services/invest/investmentService";
import { Investment } from "@/services/invest/types";
import { defineComponent, Ref, ref } from "vue";

export default defineComponent({
  components: { InvestCard, InvestimentModal },
  setup() {
    const investimentService = new InvestmentService();
    const showModal = ref<boolean>(false);
    const realData: Ref<Investment[]> = ref([]);
    const showEditModal = ref<boolean>(false);
    const selectedFirstIndex = ref<number>(0);
    const switchFirstOptions: string[] = ["Locales", "Departamentos"];

    const selectedSecondIndex = ref<number>(0);
    const switchSecondOptions: string[] = ["Locales", "Departamentos"];

    const currentPage: Ref<number> = ref(1);
    const totalPages: Ref<number> = ref(0);
    const perPage: Ref<number> = ref(0);

    function handleAddInvestment() {
      showModal.value = true;
    }

    function handleFirstSwitchingClick(option: number) {
      if (option == 1) {
        if (selectedFirstIndex.value == 1) {
          selectedFirstIndex.value = 0;
        } else {
          selectedFirstIndex.value = 1;
        }
      }
      if (option == 2) {
        if (selectedFirstIndex.value == 2) {
          selectedFirstIndex.value = 0;
        } else {
          selectedFirstIndex.value = 2;
        }
      }
      getInvestiment(currentPage.value);
    }
    function handleSecondSwitchingClick(option: number) {
      if (option == 1) {
        if (selectedSecondIndex.value == 1) {
          selectedSecondIndex.value = 0;
        } else {
          selectedSecondIndex.value = 1;
        }
      }
      if (option == 2) {
        if (selectedSecondIndex.value == 2) {
          selectedSecondIndex.value = 0;
        } else {
          selectedSecondIndex.value = 2;
        }
      }
      getInvestiment(currentPage.value);
    }

    async function getInvestiment(page = 0, next = false) {
      let result = null;
      let investment_type = "";
      let investment_status = "";

      if (selectedFirstIndex.value == 1) {
        investment_type = "COMERCIAL";
        if (!next) page = 1;
      }
      if (selectedFirstIndex.value == 2) {
        investment_type = "HABITACIONAL";
        if (!next) page = 1;
      }

      if (selectedSecondIndex.value == 1) {
        investment_status = "DISPONIBLE";
        if (!next) page = 1;
      }
      if (selectedSecondIndex.value == 2) {
        investment_status = "ARRENDADO";
        if (!next) page = 1;
      }

      try {
        result = await investimentService.getInvestiments(
          investment_status,
          investment_type,
          page
        );
        if (next == false) {
          realData.value = result.data;
        } else {
          realData.value = [...realData.value, ...result.data];
        }
        currentPage.value = result.page;
        totalPages.value = result.total_pages;
        perPage.value = result.per_page;
      } catch (error) {
        console.log(error);
      }
      return result;
    }
    const nextPage = () => {
      currentPage.value += 1;
      getInvestiment(currentPage.value, true);
    };
    getInvestiment(currentPage.value);
    return {
      showModal,
      showEditModal,
      realData,
      handleAddInvestment,
      getInvestiment,
      handleFirstSwitchingClick,
      handleSecondSwitchingClick,
      selectedFirstIndex,
      switchFirstOptions,
      selectedSecondIndex,
      switchSecondOptions,
      currentPage,
      totalPages,
      nextPage,
    };
  },
});
