export interface witnessSchema {
  readonly id: number | undefined;
  readonly rut: string;
  readonly fullName: string | undefined;
}

export class Witness implements witnessSchema {
  constructor(
    public id: number | undefined,
    public rut: string,
    public fullName: string | undefined
  ) {}
}
