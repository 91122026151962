
import TextField from "../atoms/TextField.vue";
import { computed, defineComponent, ref, toRef } from "vue";
import { Record } from "@/services/invest/types";

export default defineComponent({
  emits: ["add"],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const idRef = toRef(props, "id");
    const title = ref<Record["title"] | undefined>(undefined);
    const description = ref<Record["description"] | undefined>(undefined);
    const disableButton = ref<boolean>(false);
    function handleSubmit() {
      if (title.value && description.value) {
        const data = new Record(
          undefined,
          title.value,
          description.value,
          idRef.value,
          undefined
        );
        disableButton.value = true;
        emit("add", data);
      }
    }

    // * Error control ---------------------------------------------

    function isNullOrEmpty(value?: string | number | null): boolean {
      return !value || value == null || value == "" || value == 0;
    }
    const formHasError = computed(() => {
      const requiredFields = [title.value, description.value];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [
        titleFieldHasError.value,
        descriptionFieldHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });
    const titleFieldHasError = computed(() => {
      return isNullOrEmpty(title.value);
    });

    const descriptionFieldHasError = computed(() => {
      return isNullOrEmpty(description.value);
    });
    return {
      handleSubmit,
      title,
      description,
      disableButton,
      titleFieldHasError,
      descriptionFieldHasError,
      formHasError,
    };
  },

  components: { TextField },
});
