
import TextField from "../atoms/TextField.vue";
import { computed, defineComponent, PropType, ref } from "vue";
import {
  InvestimentEnum,
  EditInvest,
  Investment,
  InvestimentStatusEnum,
  InvestmentRequest,
} from "@/services/invest/types";
import { id } from "date-fns/locale";
import { mdiReload } from "@mdi/js";
import { isNullOrEmpty } from "@/services/utils";
import Selector from "../atoms/Selector.vue";
import SwitchButton from "../atoms/SwitchButton.vue";
import InputImage from "@/components/atoms/InputImage.vue";
import { UploadInvest } from "@/services/invest/types";

declare type enumType = keyof typeof InvestimentEnum;

export default defineComponent({
  emits: ["add", "editImage"],

  props: {
    invest: {
      type: Object as PropType<Investment>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const investmentSt = ref([
      InvestimentStatusEnum[0],
      InvestimentStatusEnum[1],
      InvestimentStatusEnum[2],
    ]);
    const id = ref<EditInvest["id"] | undefined>(props.invest.id);
    const title = ref<EditInvest["title"] | undefined>(props.invest.title);
    const description = ref<EditInvest["description"] | undefined>(
      props.invest.description
    );
    const value = ref<EditInvest["value"] | undefined>(props.invest.value);
    const disableButton = ref<boolean>(false);
    const investmentStatus = ref<string>(props.invest.investmentStatus);
    const IndexTypeSelected = ref<number>(0);
    const profiability = ref<
      InvestmentRequest["monthlyProfitabilityUf"] | undefined
    >(undefined);
    const dividendValuesOption = ref<string[]>(["UF", "Pesos Chilenos"]);
    const monthlyProfitabilityUf = ref<
      EditInvest["monthlyProfitabilityUf"] | undefined
    >(props.invest.monthlyProfitabilityUf);
    const monthlyProfitabilityClp = ref<
      EditInvest["monthlyProfitabilityClp"] | undefined
    >(props.invest.monthlyProfitabilityClp);
    function getingOptionTypeSelected(newIndexTypeSelected: number) {
      IndexTypeSelected.value = newIndexTypeSelected;
      console.log(
        "obtenemos el valor seleccionado",
        dividendValuesOption.value[IndexTypeSelected.value]
      );
    }
    function handleSubmit() {
      if (
        id.value &&
        title.value &&
        description.value &&
        value.value &&
        (monthlyProfitabilityUf.value ||
          monthlyProfitabilityClp.value ||
          profiability.value)
      ) {
        if (!monthlyProfitabilityUf.value && !monthlyProfitabilityClp.value) {
          if (IndexTypeSelected.value) {
            monthlyProfitabilityClp.value = profiability.value;
          } else {
            monthlyProfitabilityUf.value = profiability.value;
          }
        }
        const data = new EditInvest(
          id.value !== undefined ? id.value : 0,
          title.value,
          description.value,
          investmentStatus.value,
          value.value,
          monthlyProfitabilityUf.value,
          monthlyProfitabilityClp.value,
          false
        );
        disableButton.value = true;
        emit("add", data);
      }
    }
    const formHasError = computed(() => {
      const requiredFields = [title.value, description.value, value.value];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [
        titleFieldHasError.value,
        descriptionFieldHasError.value,
        investmentStatus.value,
        valueFieldHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });
    const titleFieldHasError = computed(() => {
      return isNullOrEmpty(title.value);
    });

    const descriptionFieldHasError = computed(() => {
      return isNullOrEmpty(description.value);
    });
    const valueFieldHasError = computed(() => {
      return isNullOrEmpty(value.value);
    });
    const investmentStatusFielHasError = computed(() => {
      return isNullOrEmpty(investmentStatus.value);
    });
    const profiabilityClpFieldHasError = computed(() => {
      return isNullOrEmpty(monthlyProfitabilityClp.value);
    });
    const profiabilityUfFieldHasError = computed(() => {
      return isNullOrEmpty(monthlyProfitabilityUf.value);
    });
    const profiabilityFieldHasError = computed(() => {
      return isNullOrEmpty(profiability.value);
    });

    const isLoading = ref<boolean>(false);
    async function uploadFile(file: File) {
      emit("editImage", file);
    }

    return {
      uploadFile,
      isLoading,
      id,
      title,
      description,
      profiability,
      IndexTypeSelected,
      value,
      titleFieldHasError,
      descriptionFieldHasError,
      valueFieldHasError,
      profiabilityUfFieldHasError,
      profiabilityFieldHasError,
      profiabilityClpFieldHasError,
      investmentStatusFielHasError,
      investmentSt,
      formHasError,
      monthlyProfitabilityUf,
      handleSubmit,
      getingOptionTypeSelected,
      dividendValuesOption,
      disableButton,
      investmentStatus,
      monthlyProfitabilityClp,
    };
  },

  components: { TextField, SwitchButton, Selector, InputImage },
});
