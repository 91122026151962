
import { InvestmentService } from "@/services/invest/investmentService";
import { Investment, Record } from "@/services/invest/types";
import {
  reformatDateDayMonth,
  toBadgeFormat,
  toBadgeFormatUf,
} from "@/services/utils";
import { defineComponent, ref, Ref } from "vue";
import NewModal from "../atoms/NewModal.vue";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    invest: {
      type: Investment,
      required: true,
    },
  },
  setup(props) {
    const investimentService = new InvestmentService();
    const records: Ref<Record[]> = ref([]);
    async function getInvestiment() {
      let result = null;
      try {
        if (props.invest.id) {
          result = await investimentService.getRecords(props.invest.id);
          records.value = result.data;
        }
      } catch (error) {
        console.log(error);
      }
      return result;
    }
    getInvestiment();
    return { records, reformatDateDayMonth, toBadgeFormat, toBadgeFormatUf };
  },
  components: { NewModal },
});
