
import { defineComponent } from "vue";
import ComponentCard from "../atoms/ComponentCard.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    metric: {
      type: [Number, String],
      required: true,
    },
    description: {
      type: String,
      rqeuired: false,
      default: "",
    },
    seriea: {
      type: [Number, String],
      required: true,
    },
    serieb: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    return {};
  },
  components: { ComponentCard },
});
