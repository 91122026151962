
import { defineComponent, ref } from "vue";
import { ShareholderService } from '@/services/shareholder/ShareholderService';

export default defineComponent({
  emits: ["created", "delete"],
  props: ['params'],
  setup(props, { emit }) {
    const isVisibleConfirmationModal = ref(false);
    const idShareholderSelected = ref<number>()
    const shareholderService = new ShareholderService()

    const deleteHandler = (e: Event) => {
      props.params.clicked(props.params.data.shareholderId,e)
    };

    return { deleteHandler, isVisibleConfirmationModal };
  },
});
