
import { ShareholderResume } from "@/services/shareholder/types";
import { UserService } from "@/services/user/UserService";
import { computed, defineComponent, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { formatRut } from "@/services/utils";

export default defineComponent({
  setup() {
    const router = useRouter();
    const shareholder: Ref<null | ShareholderResume> = ref(null);
    const shareholderService = new UserService();

    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );

    async function getShareholderCard() {
      const response = await shareholderService.getShareholderResume(
        shareholderId.value
      );
      if (!response.success) {
        console.log(
          "Ha ocurrido un error al intentar obtener la tarjeta de presentación del accionista"
        );
        return;
      }

      shareholder.value = response.data;
    }

    getShareholderCard();

    return {
      shareholder,
      formatRut,
    };
  },
});
