import { getEnvConfig } from "@/configs/UrlConfig";
import { getResponse, keysToSnake } from "../utils";
import { Response } from "../types";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { Account, AccountSelectedClass, AccountCreateClass } from "./types";

export class AccountService extends AuthenticatedService {
    

    async getAccountServices(idUser: number): Promise<Response<Account>> {
        const url = `${getEnvConfig().apiURL}/admin/user_accounts?user_id=${idUser}`

        return getResponse(url, this.token, 'GET', undefined, undefined)
    }
    async setAccount(
        user_id:number,
        accountCreate:AccountSelectedClass
      ): Promise<Response<Account>> {
        const url = `${getEnvConfig().apiURL
          }/admin/shareholders/account/selected?user_id=${user_id}`;
        const bodyAccountPostData =
          keysToSnake(accountCreate);
        return getResponse(
          url,
          this.token,
          "POST",
          bodyAccountPostData,
          undefined,
          Account
        );
      }
      
    async createNewAccount(
    accountPostData: AccountCreateClass
    ): Promise<Response<Account>> {
    const url = `${getEnvConfig().apiURL}/dividends/create`;
    const bodyAccountPostData = keysToSnake(accountPostData);
    return getResponse(
        url,
        this.token,
        "POST",
        bodyAccountPostData,
        undefined,
        Account
    );
    }
    


    async registerNewAccount(

        bank_code: number,
        bank_name: string,
        account_type: string,
        account_number: string,
        account_owner_name: string,
        account_owner_rut: string,
        account_email:string,
    
    ) {
        const data = {
            bank_code: bank_code,
            bank_name: bank_name,
            account_type: account_type,
            account_number: account_number,
            account_owner_name: account_owner_name,
            account_owner_rut: account_owner_rut,
            account_email:account_email,
            is_selected:0,
    
        };
        const url = `${getEnvConfig().apiURL}/admin/shareholders/account`;
    
        return getResponse(
        url,
        this.token,
        "POST",
        data,
        undefined,
        );
    }
    async asyncNewAccount(
  
        shareHolder: number,
        user_id: number,
    
    ) {
        const data = {
            user_id: user_id,
    
        };
        const url = `${getEnvConfig().apiURL}/admin/shareholders/${shareHolder}/assign-user/`;
    
        return getResponse(
        url,
        this.token,
        "PUT",
        data,
        undefined,
        );
    }
    async deleteAccount(
  
      shareHolder: number,
  
  ) {
      const data = {
          user_id: null,
  
      };
      const url = `${getEnvConfig().apiURL}/admin/shareholders/${shareHolder}/assign-user/`;
  
      return getResponse(
      url,
      this.token,
      "PUT",
      data,
      undefined,
      );
  }
  }


      

