
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores/auth";
import { AuthService } from "@/services/auth/AuthService";

export default defineComponent({
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const router = useRouter();

    const authStore = useAuthStore();
    const authService = new AuthService();

    const menuIsVisible = ref(false);
    const openingMenu = ref(false);

    function showMenu() {
      menuIsVisible.value = true;
    }
    function closeMenu() {
      menuIsVisible.value = false;
    }
    function clickOutside() {
      if (!openingMenu.value) {
        closeMenu();
      }
    }

    async function logout() {
      if (!authStore.token) return;
      const response = await authService.logout(authStore.token);
      if (response.success) {
        router.push({ name: "auth-login" });
        authStore.logout();
      }
    }

    return {
      clickOutside,
      showMenu,
      menuIsVisible,
      logout,
    };
  },
});
