export class Investment {
  constructor(
    readonly id: number | undefined,
    public title: string,
    readonly description: string,
    readonly investmentType: string,
    readonly investmentStatus: string,
    readonly value: number,
    readonly monthlyProfitabilityUf: number | undefined,
    readonly monthlyProfitabilityClp: number| undefined,
    readonly thumbnail: string,
    readonly isDeleted: boolean
  ) {}
}
export class InvestmentRequest {
  constructor(
    readonly id: number | undefined,
    public title: string,
    readonly description: string,
    readonly investmentType: string,
    readonly investmentStatus: string,
    readonly value: number,
    readonly monthlyProfitabilityUf: number | undefined ,
    readonly monthlyProfitabilityClp: number | undefined,
    readonly thumbnail: File,
    readonly isDeleted: boolean
  ) {}
}

export class EditInvest {
  constructor(
    readonly id: number ,
    public title: string,
    readonly description: string,
    readonly investmentStatus: string,
    readonly value: number,
    readonly monthlyProfitabilityUf: number | undefined,
    readonly monthlyProfitabilityClp: number| undefined,
    readonly isDeleted: boolean

  ) {}
}

export class UploadInvest {
  constructor(readonly id: number, readonly image: File) {}
}

export class Record {
  constructor(
    readonly id: number | undefined,
    public title: string,
    readonly description: string,
    readonly investmentId: number,
    readonly createdAt: string | undefined,
  ) {}
}

export enum InvestimentEnum {
  "HABITACIONAL" = 0,
  "COMERCIAL" = 1,
  "" = 2,
}

export enum InvestimentStatusEnum {
  "DISPONIBLE" = 0,
  "ARRENDADO" = 1,
  "VENDIDO" = 2,
}
