
import { defineComponent } from "vue";
import { contractOptions } from "@/services/movements/types";

export default defineComponent({
  props: {
    movementId: {
      type: Number,
      required: true,
    },
    isContractSigned: {
      type: Boolean,
    },
  },
  emits: ["buttonAction"],
  setup(props, { emit }) {
    function selectionHandler(option: contractOptions) {
      emit("buttonAction", option, props.movementId);
    }
    return { selectionHandler, contractOptions };
  },
});
