
import { defineComponent, computed, ref, Ref } from "vue";
import {
  contractOptions,
  ShareholderMovement,
} from "@/services/movements/types";
import { useRouter } from "vue-router";
import { reformatDate, toBadgeFormat, toBadgeFormatUf } from "@/services/utils";
import { shareMovementStore } from "@/stores/movements";
import { useShareholderStore } from "@/stores/shareholder";
import { DividendService } from "@/services/dividends/dividendService";
import { DividendStatusType, PaymentStatus } from "@/services/dividends/types";
import moment from "moment";

export default defineComponent({
  name: "ShareholdersShares",
  data() {
    return {};
  },
  setup() {
    const shareholderStore = useShareholderStore();

    const router = useRouter();

    const dividendService = new DividendService();
    const dividends = ref<PaymentStatus[]>([]);
    const idMovementSelected = ref<number>();
    const shareholderMoves: Ref<ShareholderMovement[] | null> = ref(null);
    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );

    async function getDividends() {
      let dividend = await dividendService.getShareholderDividends(
        shareholderId.value
      );
      if (!dividend.success) {
        console.log("Error al obtener los dividendos");
        return false;
      }
      dividends.value = dividend.data;
    }
    getDividends();
    function getPrettyPrintStatus(status: DividendStatusType) {
      switch (status) {
        case DividendStatusType.PAID:
          return "Pagado";
        case DividendStatusType.PENDING:
          return "Pendiente";
        case DividendStatusType.REJECTED:
          return "Rechazado";
        default:
          break;
      }
      return status;
    }

    return {
      toBadgeFormatUf,
      reformatDate,
      toBadgeFormat,
      getPrettyPrintStatus,
      dividends,
      shareholderId,
      idMovementSelected,
      moment,
    };
  },
  components: {},
});
