
import Modal from "@/components/atoms/Modal.vue";
import { defineComponent, ref, watch } from "vue";
import { PropType } from "vue";
import { DividendStatusType, PaymentStatus, DividendsCompleteData } from "@/services/dividends/types";

import TextField from "@/components/atoms/TextField.vue";
import Selector from "@/components/atoms/Selector.vue";
import { DividendService } from "@/services/dividends/dividendService";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dividend: {
      type: Object as PropType<PaymentStatus>,
      required: true
    },
  },
  emits: ["update:visible", "close", 'update'],
  components: { Modal, TextField, Selector },
  setup(prop, { emit }) {
    const banckSelected = ref<string>("");
    const dividendNewStatus = ref<string>(getPrettyPrintStatus(prop.dividend?.status));
    const completeData = ref<DividendsCompleteData>(new DividendsCompleteData())
    const shouldToSendEmail = ref<boolean>(false);
    const enableButton = ref<boolean>(false);
    const dividendService = new DividendService();
    async function submitHandler() {
      completeData.value.status = dividendNewStatus.value
      completeData.value.paymentId = prop.dividend.id
      console.log(completeData.value)
      let res = await dividendService.completePayment(completeData.value);
      if (!res.success) {
        
        emit('update:visible', false)
        return false;
      }
      alert(" Modificado correctamente");
      emit('update', false)
      emit('update:visible', false)
    }

    function closeModal() {


      emit('update:visible', false)
    }
    watch(completeData.value, (currValue) => {
      enableButton.value = true
    })
    watch(dividendNewStatus, (currValue) => {
      
      enableButton.value = true
    })
    function getPrettyPrintStatus(status: DividendStatusType) {
      switch (status) {
        case DividendStatusType.PAID:
          return "Pagado";
        case DividendStatusType.PENDING:
          return "Pendiente";
        case DividendStatusType.REJECTED:
          return "Rechazado";
        default:
          break;
      }
      return status;
    }
    return { banckSelected, enableButton, shouldToSendEmail, DividendStatusType, dividendNewStatus, completeData, getPrettyPrintStatus, submitHandler, closeModal, };
  },
});
