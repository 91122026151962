
import { Component, defineComponent, PropType, Ref, ref } from "vue";

export interface Tab {
  name: string;
  view: Component;
}

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<Array<Tab>>,
      required: true,
    },
  },

  setup(props) {
    const activeTab: Ref<Tab | null> = ref(props.tabs[0]);

    function setTab(tab: Tab) {
      activeTab.value = tab;
    }

    return {
      activeTab,
      setTab,
    };
  },
});
