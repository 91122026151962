import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a80005b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inline-block" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-500 ml-auto flex place-items-center"
}
const _hoisted_3 = { class: "inline-block relative w-full" }
const _hoisted_4 = { class: "flex gap-1 flex-1 items-center max-w-full" }
const _hoisted_5 = { class: "truncate" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex gap-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Popper = _resolveComponent("Popper")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["flex text-sm font-semibold pb-1", { 'text-red-500': _ctx.hasError, 'text-gray-700': !_ctx.hasError }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.hasError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_Popper, {
                  arrow: "",
                  class: "font-normal",
                  placement: "auto",
                  hover: "",
                  content: _ctx.errorMessage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_mdicon, {
                      name: "error",
                      size: "16"
                    })
                  ]),
                  _: 1
                }, 8, ["content"])
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isActive = !_ctx.isActive)),
        class: _normalizeClass(["appearance-none border rounded-sm w-full py-1 px-2 leading-tight focus:outline-none cursor-pointer flex items-center h-[38px] relative select-none bg-white", {
          'text-gray-700 border-gray-300 focus:border-black hover:border-gray-400':
            !_ctx.hasError,
          'text-red-700 border-red-300 focus:border-red-800 hover:border-red-400':
            _ctx.hasError,
        }])
      }, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.iconName)
            ? (_openBlock(), _createBlock(_component_mdicon, {
                key: 0,
                name: _ctx.iconName,
                size: "20"
              }, null, 8, ["name"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.placeholder), 1)
        ]),
        _createVNode(_component_mdicon, {
          class: "absolute right-2 bg-white",
          name: "chevron-down",
          size: "20"
        })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([{
          block: _ctx.isActive,
          hidden: !_ctx.isActive,
          'bottom-9 rounded-t-lg': _ctx.onTop,
          'rounded-b-lg': !_ctx.onTop,
        }, "absolute text-gray-700 bg-white left-0 w-full min-w-max border-gray-200 border-[0.5px] shadow-lg overflow-y-auto max-h-36 z-50 select-none"])
      }, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, key) => {
            return (_openBlock(), _createElementBlock("li", {
              key: key,
              class: _normalizeClass([_ctx.getItemExtraClass(item as string), "hover:bg-primary-50 active:bg-primary-100 py-2 px-4 block whitespace-no-wrap cursor-pointer"]),
              onClick: ($event: any) => (_ctx.switchSelection(item as string))
            }, [
              _createElementVNode("div", _hoisted_7, [
                (_ctx.icons)
                  ? (_openBlock(), _createBlock(_component_mdicon, {
                      key: 0,
                      name: _ctx.icons[key],
                      size: "20"
                    }, null, 8, ["name"]))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(item), 1)
              ])
            ], 10, _hoisted_6))
          }), 128))
        ])
      ], 2)
    ])), [
      [_directive_click_outside, () => (_ctx.isActive = false)]
    ])
  ]))
}