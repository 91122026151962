import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminTopbar = _resolveComponent("AdminTopbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showTopbar)
      ? (_openBlock(), _createBlock(_component_AdminTopbar, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, {
      class: _normalizeClass({
      'mt-28 mb-10 mx-10': _ctx.showTopbar,
    })
    }, null, 8, ["class"])
  ], 64))
}