import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between font-bold text-[12px]" }
const _hoisted_2 = { class: "text-[12px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-left transition-colors grid grid-cols-12 items-center", { 'text-gray-400': !_ctx.actived, 'text-gray-900': _ctx.actived }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["text-left transition-colors col-span-10", { 'text-gray-400': !_ctx.actived, 'text-gray-900': _ctx.actived }])
    }, [
      _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.description), 1)
    ], 2),
    _createVNode(_component_Switch, {
      class: "col-span-2",
      actived: _ctx.actived,
      "onUpdate:actived": _cache[0] || (_cache[0] = ($event) => _ctx.handleUpdate($event))
    }, null, 8, ["actived"])
  ], 2))
}