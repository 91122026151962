
import { defineComponent, ref } from "vue";
NewDividendForm;
import {
  formOptionsType,
  DividendCreateClass,
} from "@/services/dividends/types";
import { DividendService } from "@/services/dividends/dividendService";
import NewDividendForm from "./NewDividendForm.vue";
import FilterForm from "./FilterForm.vue";
import Swal from "sweetalert2";
import { DividendsFilter } from "@/services/dividends/types";

export default defineComponent({
  props: {
    formOptionSelected: {
      type: Number,
      required: true,
    },
  },
  components: {
    NewDividendForm,
    FilterForm,
  },
  emits: ["closeModals", "aplyFilter", "getDivident", "getAllDividends"],
  setup(props, { emit }) {
    const dividendsService = new DividendService();
    const loadingForm = ref<boolean>(false);

    async function submitNewDividentForm(
      valuesDividendForm: DividendCreateClass
    ) {
      try {
        loadingForm.value = true;
        const res = await dividendsService.createNewDivident(
          valuesDividendForm
        );

        if (res.success) {
          //alert("Se ha registrado el dividendo de manera exitosa");
          loadingForm.value = false;
          Swal.fire(
            "¡Listo!",
            "La información se ha modificado de forma éxitosa",
            "success"
          );
          emit("closeModals");
          emit("getAllDividends", 1);
          loadingForm.value = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        console.log("entra aca");
      }
    }
    function submitFilterForm(dividendFilter: DividendsFilter) {
      emit("aplyFilter", dividendFilter);
      emit("closeModals");
    }

    return {
      submitNewDividentForm,
      submitFilterForm,
      formOptionsType,
      loadingForm,
    };
  },
});
