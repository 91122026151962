
import { defineComponent} from 'vue'

enum Severity{
    warning = 'warning',
    error = "error"
}


export default defineComponent({
    props: {
        message: {
            type: String,
            required: true
        },

        severity: {
            type: String as () => keyof typeof Severity,
            required: true
        }
    },

    setup() {
        return {
        }
    },
})
