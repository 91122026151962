
import TextField from "../atoms/TextField.vue";
import { computed, defineComponent, ref } from "vue";
import Selector from "../atoms/Selector.vue";
import {
  InvestimentEnum,
  InvestimentStatusEnum,
  InvestmentRequest,
} from "@/services/invest/types";
import SwitchButton from "../atoms/SwitchButton.vue";
import { upercaseToTitle } from "@/services/utils";

declare type enumType = keyof typeof InvestimentEnum;

export default defineComponent({
  emits: ["add"],
  setup(props, { emit }) {
    const investmentType = ref<enumType>("");
    const investmentTypes = ref([
      upercaseToTitle(InvestimentEnum[0]),
      upercaseToTitle(InvestimentEnum[1]),
    ]);
    const investmentStatus = ref<enumType>("");
    const investmentSt = ref([
      upercaseToTitle(InvestimentStatusEnum[0]),
      upercaseToTitle(InvestimentStatusEnum[1]),
      upercaseToTitle(InvestimentStatusEnum[2]),
    ]);
    const title = ref<InvestmentRequest["title"] | undefined>(undefined);
    const description = ref<InvestmentRequest["description"] | undefined>(
      undefined
    );
    const thumbnail = ref<InvestmentRequest["thumbnail"] | undefined>(
      undefined
    );
    const value = ref<InvestmentRequest["value"] | undefined>(undefined);
    const disableButton = ref<boolean>(false);
    const profiability = ref<
      InvestmentRequest["monthlyProfitabilityUf"] | undefined
    >(undefined);
    const dividendValuesOption = ref<string[]>(["UF", "Pesos Chilenos"]);
    const IndexTypeSelected = ref<number>(0);
    function handleSubmit() {
      if (
        title.value &&
        description.value &&
        investmentType.value.toUpperCase() &&
        investmentStatus.value.toUpperCase() &&
        thumbnail.value &&
        value.value &&
        profiability.value
      ) {
        const data = new InvestmentRequest(
          undefined,
          title.value,
          description.value,
          investmentType.value,
          investmentStatus.value,
          value.value,
          IndexTypeSelected.value ? undefined : profiability.value,
          IndexTypeSelected.value ? profiability.value : undefined,
          thumbnail.value,
          false
        );
        disableButton.value = true;
        emit("add", data);
      }
    }
    function getingOptionTypeSelected(newIndexTypeSelected: number) {
      IndexTypeSelected.value = newIndexTypeSelected;
      console.log(
        "obtenemos el valor seleccionado",
        dividendValuesOption.value[IndexTypeSelected.value]
      );
    }
    // * Error control ---------------------------------------------

    function isNullOrEmpty(value?: string | number | null | File): boolean {
      return !value || value == null || value == "" || value == 0;
    }

    const formHasError = computed(() => {
      const requiredFields = [
        title.value,
        description.value,
        investmentType.value,
        investmentStatus.value,
        thumbnail.value,
        value.value,
        profiability.value,
      ];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [
        titleFieldHasError.value,
        descriptionFieldHasError.value,
        thumbnailFieldHasError.value,
        valueFieldHasError.value,
        investmentTypeFieldHasError.value,
        investmentStatusFielHasError.value,
        profiabilityFieldHasError.value,
        investmentStatusFielHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });
    const titleFieldHasError = computed(() => {
      return isNullOrEmpty(title.value);
    });

    const descriptionFieldHasError = computed(() => {
      return isNullOrEmpty(description.value);
    });
    const thumbnailFieldHasError = computed(() => {
      return isNullOrEmpty(thumbnail.value);
    });
    const valueFieldHasError = computed(() => {
      return isNullOrEmpty(value.value);
    });
    const investmentTypeFieldHasError = computed(() => {
      return isNullOrEmpty(investmentType.value);
    });
    const investmentStatusFielHasError = computed(() => {
      return isNullOrEmpty(investmentStatus.value);
    });
    const profiabilityFieldHasError = computed(() => {
      return isNullOrEmpty(profiability.value);
    });

    return {
      investmentType,
      investmentStatus,
      formHasError,
      title,
      IndexTypeSelected,
      description,
      thumbnail,
      dividendValuesOption,
      value,
      profiability,
      titleFieldHasError,
      descriptionFieldHasError,
      thumbnailFieldHasError,
      valueFieldHasError,
      profiabilityFieldHasError,
      investmentTypes,
      investmentTypeFieldHasError,
      investmentSt,
      investmentStatusFielHasError,
      disableButton,
      handleSubmit,
      getingOptionTypeSelected,
    };
  },

  components: { TextField, Selector, SwitchButton },
});
