
import { ShareholderMovement } from "@/services/movements/types";
import { defineStore } from "pinia";
import { MovementService } from "@/services/movements/MovementService";

export const shareMovementStore = defineStore("movements", {
    state: () => {
        const movements : ShareholderMovement[]  = [];
        const shareholderShareService = new MovementService();
        const promise: Promise<Response> = new Promise(res=>(res));

        return {
            movements : movements ,
            shareholderShareService,
            promise
        }
    },

    actions: {
        async query(id : number){
             await this.shareholderShareService
               .getShareholderMovement(id)
               .then(({data})=> (this.movements = data) ); 
        },
    },

    getters: {
        getData(state){
            return state.movements 
        }
    }
})

export type AuthStore = ReturnType<typeof shareMovementStore>;