
import { defineComponent, computed, ref, Ref } from "vue";
import {
  contractOptions,
  ShareholderMovement,
} from "@/services/movements/types";

import { useRouter } from "vue-router";
import { reformatDate } from "@/services/utils";
import NewMovementModal from "@/components/organisms/NewMovementModal.vue";
import { shareMovementStore } from "@/stores/movements";
import { useShareholderStore } from "@/stores/shareholder";
import ContractButonGroup from "../molecules/contract/ContractButonGroup.vue";
import CreateContactModal from "../molecules/contract/CreateContactModal.vue";
import UploadContractModal from "../molecules/contract/UploadContractModal.vue";
import SignedContractModal from "@/components/molecules/contract/SignedContractModal .vue";
import NewModal from "../atoms/NewModal.vue";
import { MovementService } from "@/services/movements/MovementService";
import { User } from "@/services/auth/types";
import moment from "moment";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ShareholdersShares",
  data() {
    return {};
  },
  setup() {
    const shareholderStore = useShareholderStore();
    const movementsStore = shareMovementStore();
    const router = useRouter();
    const showNewMovementModal = ref(false);
    const showCreateContractModal = ref(false);
    const isVisibleConfirmationModal = ref(false);
    const showUploadContractModal = ref(false);
    const showSignedContractModal = ref(false);
    const idMovementSelected = ref<number>();
    const shareholderMoves: Ref<ShareholderMovement[] | null> = ref(null);
    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );
    const movementService = new MovementService();
    const shareholder = computed(() => shareholderStore.shareholder);
    const movements = computed(() => {
      let totalGroupAAmount = 0;
      let totalGroupBAmount = 0;

      if (!movementsStore.movements) return [];
      return [...movementsStore.movements]
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        .reverse() // Invierte el array para recorrerlo en orden inverso
        .map((movement) => {
          if (movement.buyerUserId == shareholderId.value) {
            totalGroupAAmount += movement.groupAAmount;
            totalGroupBAmount += movement.groupBAmount;
          } else {
            totalGroupAAmount -= movement.groupAAmount;
            totalGroupBAmount -= movement.groupBAmount;
          }

          movement.AFinalAmount = totalGroupAAmount;
          movement.BFinalAmount = totalGroupBAmount;

          return movement;
        })
        .reverse(); // Invierte el array de nuevo para restaurar el orden original
    });

    function documentSelectorHandler(
      contractOptionSelected: contractOptions,
      movementIdSelected: number
    ) {
      idMovementSelected.value = movementIdSelected;
      switch (contractOptionSelected) {
        case contractOptions.generate:
          showCreateContractModal.value = true;
          break;
        case contractOptions.upload:
          showUploadContractModal.value = true;
          break;
        case contractOptions.signed:
          showSignedContractModal.value = true;
          break;
        default:
          break;
      }
      return false;
    }
    function reload() {
      movementsStore.query(shareholderId.value);
    }
    function deleteHandler(movementId: number) {
      isVisibleConfirmationModal.value = !isVisibleConfirmationModal.value;
      idMovementSelected.value = movementId;
    }
    async function deleteMovement() {
      if (!idMovementSelected.value) return false;
      const response = await movementService.deleteMovement(
        idMovementSelected.value
      );
      if (!response.success) {
        Swal.fire(
          "¡Error!",
          "Algo salió mal, vuelva a recargar la página.",
          "error"
        );
      }
      Swal.fire("¡Listo!", "Movimiento eliminado con éxito", "success");
      isVisibleConfirmationModal.value = !isVisibleConfirmationModal.value;
      reload();
      movementsStore.query(shareholderId.value);
      shareholderStore.query(shareholderId.value);
    }

    function isBuyer(movement: ShareholderMovement) {
      return movement.buyerUserId == shareholder.value?.id;
    }

    function getAmountA(movement: ShareholderMovement) {
      if (isBuyer(movement)) return movement.groupAAmount ?? "0";
      return -movement.groupAAmount;
    }

    function getAmountB(movement: ShareholderMovement) {
      if (isBuyer(movement)) return movement.groupBAmount;
      return -movement.groupBAmount;
    }

    function getFinalAmountA(movement: ShareholderMovement) {
      if (isBuyer(movement)) return movement.buyerGroupAFinalAmount;
      return movement.sellerGroupAFinalAmount;
    }

    function getFinalAmountB(movement: ShareholderMovement) {
      if (isBuyer(movement)) return movement.buyerGroupBFinalAmount;
      return movement.sellerGroupBFinalAmount;
    }
    function getSellerShareholder(movement: ShareholderMovement) {
      let isAMovement = movement.groupAAmount ? true : false;
      if (isAMovement) {
        if (movement?.sellerUser?.investorNumberA == undefined) {
          return "-";
        }
        return `[ID-A ${movement.sellerUser?.investorNumberA}] ${movement.sellerUser?.firstName} ${movement.sellerUser?.lastName}`;
      }
      if (movement?.sellerUser?.investorNumberB == undefined) {
        return "-";
      }
      return `[ID-B ${movement.sellerUser?.investorNumberB}] ${movement.sellerUser?.firstName} ${movement.sellerUser?.lastName}`;
    }
    function getBuyerShareholder(movement: ShareholderMovement) {
      let isAMovement = movement.groupAAmount ? true : false;

      if (isAMovement) {
        if (movement?.buyerUser?.investorNumberA == undefined) {
          return "-";
        }
        return `[ID-A ${movement.buyerUser?.investorNumberA}] ${movement.buyerUser?.firstName} ${movement.buyerUser?.lastName}`;
      }
      if (movement?.buyerUser?.investorNumberB == undefined) {
        return "-";
      }
      return `[ID-B ${movement.buyerUser?.investorNumberB}] ${movement.buyerUser?.firstName} ${movement.buyerUser?.lastName}`;
    }
    movementsStore.query(shareholderId.value);
    return {
      reformatDate,
      getSellerShareholder,
      getBuyerShareholder,
      documentSelectorHandler,
      showUploadContractModal,
      showSignedContractModal,
      shareholder,
      shareholderMoves,
      showCreateContractModal,
      showNewMovementModal,
      movements,
      shareholderId,
      idMovementSelected,
      getAmountA,
      getAmountB,
      getFinalAmountA,
      getFinalAmountB,
      reload,
      deleteMovement,
      deleteHandler,
      isVisibleConfirmationModal,
      moment,
    };
  },
  components: {
    SignedContractModal,
    UploadContractModal,
    CreateContactModal,
    NewMovementModal,
    ContractButonGroup,
    NewModal,
  },
});
