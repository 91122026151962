
import { computed, defineComponent, ref, onMounted } from "vue";
import NewModal from "@/components/atoms/NewModal.vue";

import { useAuthStore } from "@/stores/auth";
import { AccountService } from "@/services/account/AccountService";
import { ShareholderService } from "@/services/shareholder/ShareholderService";
import Selector from "@/components/atoms/Selector.vue";
import { useShareholderStore } from "@/stores/shareholder";
import { accountStore } from "@/stores/account";
import { ShareholderResume } from "@/services/shareholder/types";
import Swal from "sweetalert2";

import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: { NewModal, Selector },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:visible"],
  setup(props, { emit }) {
    const accountStoreStr = accountStore();
    const authStore = useAuthStore();

    const shareholderStr = useShareholderStore();
    const router = useRouter();
    // const getMovementCodSpanish = (key: enumType) => MovementEnumSpanish[key];
    const shareholderId = parseInt(localStorage.getItem("user_id") || "0", 10);
    const shareholderService = new ShareholderService();

    const accountService = new AccountService();
    const loading = ref(false);
    const hasError = ref(false);
    const errorMessage = ref("");
    const route = useRoute();

    // Información basica

    const shareholderSelected = ref<number>(-1);

    /**
     * Comprueba si el valor ingresado como parámetro es nulo o no definido.
     * @param value Valor a comprobar
     */
    function isNullOrEmpty(value?: string | number | null): boolean {
      return !value || value == null || value == "" || value == 0;
    }
    function isEmptyOrZero(value?: number): boolean {
      return !value || value == null || value == 0;
    }
    function isEmptyOrLarge(value?: string): boolean {
      return (
        !value ||
        value == null ||
        value == "" ||
        value.length == 0 ||
        value.length > 35
      );
    }
    function isRut(value?: string): boolean {
      return (
        !value ||
        value == null ||
        value == "" ||
        value.length == 0 ||
        value.length > 35
      );
    }

    // Indica si el formulario tiene algun error de dato o valor requerido
    const formHasError = computed(() => {
      const requiredFields = [shareholderSelected.value];

      if (shareholderSelected.value == -1) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [typeActionFieldHasError.value];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });

    // const dateFieldHasError = computed(() => {
    //   return isNullOrEmpty(date.value);
    // });

    const typeActionFieldHasError = computed(() => {
      return isNullOrEmpty(shareholderSelected.value);
    });
    /**
     * Envia una solicitud al backend para registrar al nuevo accionista
     */
    const shareholders = ref<ShareholderResume[]>([]);
    const shareholderItems = computed(() => {
      console.log(shareholders.value);
      if (shareholders.value == null || shareholders.value == undefined) {
        return ["No hay accionistas diponibles"];
      }
      let shareholderAux = shareholders.value.map((shareholder) => {
        let AStringLabel = shareholder.investorNumberA
          ? " A  [" + shareholder.investorNumberA + "] -  "
          : "";
        let BStringLabel = shareholder?.investorNumberB
          ? " B  [" + shareholder?.investorNumberB + "] - "
          : "";
        return AStringLabel + BStringLabel + shareholder.identificationName;
      });
      if (shareholderAux.length == 0) {
        return ["No hay accionistas diponibles"];
      }
      return ["Dhelos", ...shareholderAux];
    });
    const shareholderValues = computed(() => {
      if (shareholders.value == null || shareholders.value == undefined) {
        return [-1];
      }
      let shareholderAux = shareholders.value.map(
        (shareholder) => shareholder.id
      );
      return [-1, ...shareholderAux];
    });

    async function getShareholders() {
      const response = await shareholderService.getShareholdersNoWithAccount();
      if (!response.success) {
        shareholders.value = [];
        return;
      }
      shareholders.value = response.data;
    }

    onMounted(() => {
      getShareholders();
    });

    async function registerNewAccount() {
      loading.value = true;

      try {
        const response = await accountService.asyncNewAccount(
          shareholderSelected.value,
          +route.params.id
        );
        if (response.success == true) {
          loading.value = false;
          emit("update:visible", false);

          if (authStore.user?.id) {
            accountStoreStr.query(+route.params.id);
          }
          getShareholders();
          shareholderSelected.value = -1;
          Swal.fire(
            "¡Listo!",
            "El accionista fue asociado con éxito",
            "success"
          );
        } else {
          Swal.fire(
            "¡Error!",
            "Lo sentimos, hubo un error, intente más tarde",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      loading,
      hasError,
      errorMessage,
      registerNewAccount,
      isNullOrEmpty,

      shareholderSelected,
      shareholderItems,
      shareholderValues,
      typeActionFieldHasError,

      formHasError,
    };
  },
});
