
import NewModal from "@/components/atoms/NewModal.vue";

import { defineComponent, ref } from "vue";
import { MovementService } from "@/services/movements/MovementService";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      defaul: false,
    },
    movementId: {
      type: Number,
      required: true,
    },
  },
  components: {
    NewModal,
  },
  setup(prop, { emit }) {
    const movementService = new MovementService();
    const isLoading = ref<boolean>(true);
    async function getContract() {
      let res = await movementService.getContract(prop.movementId);
      if (!res) {
        alert("Error al cargar el documento");
      }
      const blob: Blob = new Blob([res], {
        type: "application/pdf; charset=utf-8",
      });
      let blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
      emit("update:visible", false);
    }
    getContract();
    return { isLoading };
  },
});
