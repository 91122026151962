
import { InvestmentService } from "@/services/invest/investmentService";
import { EditInvest, Investment, Record } from "@/services/invest/types";
import { PropType, defineComponent, toRef, ref } from "vue";
import NewModal from "../atoms/NewModal.vue";
import FormEditInvestment from "../molecules/FormEditInvestment.vue";
import FormRecord from "../molecules/FormRecord.vue";
import Swal from "sweetalert2";
import { id } from "date-fns/locale";
import { UploadInvest } from "@/services/invest/types";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
    invest: {
      type: Object as PropType<Investment>,
      required: true,
    },
  },
  emits: ["update:visible", "reload"],

  setup(props, { emit }) {
    const idRef = toRef(props, "id");
    const investmentService = new InvestmentService();
    const isLoading = ref<boolean>(false);
    const fileData = ref<any>(null);
    async function editInvestiment(data: EditInvest) {
      let res = null;
      try {
        res = await investmentService.editInvestiment(data);
        if (res.success) {
          if (fileData.value != null) {
            editImageInvestiment(fileData.value);
            return;
          }
          emit("reload");
          emit("update:visible", false);
          Swal.fire(
            "¡Listo!",
            "La información se ha modificado de forma éxitosa",
            "success"
          );
        }
      } catch (error) {
        console.log(error);
        Swal.fire(
          "¡Error!",
          "Lo sentimos, hubo un error en el ingreso de la información",
          "error"
        );
      }
      return data;
    }
    async function saveData(data: any) {
      fileData.value = data;
    }
    async function editImageInvestiment(data: any) {
      let res = null;
      isLoading.value = true;
      if (props.invest.id) {
        let contractData = new UploadInvest(props.invest.id, data);
        res = await investmentService.uploadImage(contractData);
        if (!res.success) {
          Swal.fire(
            "¡Error!",
            "Lo sentimos, hubo un error en la subida de imagen",
            "error"
          );

          isLoading.value = false;

          return false;
        }
        Swal.fire(
          "¡Listo!",
          "La información se ha modificado de forma éxitosa, recargue la página para poder visualizar la imagen",
          "success"
        );
        isLoading.value = false;
      }
      window.location.reload();
      emit("reload");
      emit("update:visible", false);
      return res;
    }
    async function registerRecord(data: Record) {
      let res = null;
      try {
        res = await investmentService.createRecord(data);
        if (res.success) {
          emit("reload");
          emit("update:visible", false);
        }
      } catch (error) {
        console.log(error);
      }
      return res;
    }
    return {
      editInvestiment,
      editImageInvestiment,
      saveData,
      registerRecord,
      idRef,
    };
  },
  components: { NewModal, FormEditInvestment, FormRecord },
});
