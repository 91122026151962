
import { User } from "@/services/auth/types";
import { computed, defineComponent, ref, Ref, markRaw } from "vue";
import { useRouter } from "vue-router";
import UserCard from "@/components/molecules/UserCard.vue";
import TabTemplate from "@/components/templates/TabTemplate.vue";
import ShareholderIdentificationTab from "@/views/admin/tabs/ShareholderIdentificationTab.vue";
import UserSharesTab from "@/views/admin/tabs/UserSharesTab.vue";
import UserIdentificationTab from "@/views/admin/tabs/UserIdentificationTab.vue";
import { useUserStore } from "@/stores/user";
import ShareholderNotificationSettingsTab from "./tabs/ShareholderNotificationSettingsTab.vue";

export default defineComponent({
  components: { UserCard, TabTemplate },

  setup() {
    const shareholderStore = useUserStore();
    const router = useRouter();
    const shareholder: Ref<User | null> = ref(null);

    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );

    shareholderStore.query(shareholderId.value);

    return {
      shareholder,
      UserCard,
      ShareholderIdentificationTab,
      UserSharesTab,
      ShareholderNotificationSettingsTab,
      markRaw,
      UserIdentificationTab,
    };
  },
});
