
import Modal from "@/components/atoms/Modal.vue";
import Selector from "@/components/atoms/Selector.vue";
import NewTableComponent from "@/components/molecules/NewTableComponent.vue";
import DividendDetailModal from "@/components/organisms/dividends/DividendDetailModal.vue";
import GenericDividensForm from "@/components/organisms/dividends/GenericDividendsForm.vue";
import { DividendService } from "@/services/dividends/dividendService";
import { ShareholderService } from "@/services/shareholder/ShareholderService";
import {
  PaymentStatus,
  DividendLabelOptionType,
  DividendType,
  formOptionsType,
  DividendsFilter,
  DividendStatusType,
  MultiplePaymentsData,
} from "@/services/dividends/types";
import { reformatDate, toBadgeFormat } from "@/services/utils";
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import Swal from "sweetalert2";

import { useRouter, useRoute } from "vue-router";

import { Shareholder } from "../../services/shareholder/types";

export default defineComponent({
  components: {
    DividendDetailModal,
    NewTableComponent,
    GenericDividensForm,
    Selector,
    Modal,
  },
  setup() {
    const dividendNewStatus = ref<DividendStatusType>(
      DividendStatusType.PENDING
    );
    const dividendService = new DividendService();
    const shareholderService = new ShareholderService();
    const showDeleteConfirmation = ref<boolean>(false);
    const clickedOutsideModal = ref<boolean>(false);
    const selectedDividendToDelete = ref<number>();
    const showNewDividendModal = ref<boolean>(false);
    const isVisibleConfirmationModal = ref<boolean>(false);
    const showDetailModal = ref<boolean>(false);
    const showFilterModal = ref<boolean>(false);
    const dividendPayments = ref<PaymentStatus[]>([]);
    const selectedDividendPayments = ref<number[]>([]);
    const areAllDividendsSelected = ref<boolean>(false);
    const dividendDataSelected = ref<PaymentStatus>();
    const dividendFilter = ref<DividendsFilter>(new DividendsFilter());

    const router = useRouter();
    const route = useRoute();
    const dividendsId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );

    const columnsUnits = ref([
      { name: "pendingDate", label: "Fecha" },
      { name: "accountOwnerName", label: "Destinatario" },
      { name: "totalClpStringFormat", label: "Monto" },
      { name: "typeAction", label: "Tipo de Acción" },
      { name: "statusStringFormat", label: "Estado" },
      { name: "accountNumber", label: "Cuenta destino" },
    ]);
    const columnsDividendos = ref([
      { name: "cutoffDate", label: "Fecha de corte" },
      { name: "distributionDate", label: "Fecha de distribución" },
      { name: "divType", label: "Tipo de dividendo" },
      { name: "ufPerShare", label: "Uf por acción" },
      { name: "totalShares", label: "Cantidad de acciones" },
      { name: "totalShareholders", label: "Cantidad de accionistas" },
    ]);
    const dividends = ref<DividendType[]>([]);
    onMounted(async () => {
      //getAllDividends();
      getDividents();

      filterDividendHandler(dividendsId.value);
    });
    async function getAllDividends() {
      let res = await dividendService.getAllDividends();
      if (!res.success) {
        /*alert(
          "Ha ocurrido un error al cargar los dividendos. Vuelve a intentarlo."
        );*/
        dividends.value = [];
        return false;
      }
      dividends.value = res.data;
      dividends.value = dividends.value.sort((dividend1, dividend2) => {
        return (
          new Date(dividend2.distributionDate).getTime() -
          new Date(dividend1.distributionDate).getTime()
        );
      });
    }
    async function getDividents() {
      const response = await dividendService.getDividendPayments(
        dividendsId.value
      );
      if (!response.success) {
        dividendPayments.value = [];
        return;
      }

      dividendPayments.value = response.data;
      dividendPayments.value = dividendPayments.value.sort(
        (dividend1, dividend2) => {
          return (
            new Date(dividend2.pendingDate).getTime() -
            new Date(dividend1.pendingDate).getTime()
          );
        }
      );
      dividendPayments.value = dividendPayments.value.map((dividend) => {
        let newRow = {
          ...dividend,
          accountOwnerName:
            dividend.shareGroup == "GROUP_B"
              ? "ID B: " +
                dividend.investorNumberB +
                " - " +
                dividend.accountOwnerName
              : "ID A: " +
                dividend.investorNumberA +
                " - " +
                dividend.accountOwnerName,

          typeAction: dividend.shareGroup == "GROUP_B" ? `ID B` : `ID A`,
          pendingDate: reformatDate(
            dividend.pendingDate.toString(),
            true,
            false
          ),
          totalClpStringFormat: dividend.totalClp
            ? "$ " + toBadgeFormat(dividend.totalClp)
            : "-",
          statusStringFormat: dividend.status
            ? getPrettyPrintStatus(dividend.status)
            : "-",
        };

        return newRow;
      });
    }

    watch(areAllDividendsSelected, () => {
      if (areAllDividendsSelected.value) {
        let selectAllFromDate = dividendPayments.value.map((dividend) => {
          return dividend.id;
        });
        selectedDividendPayments.value = selectAllFromDate;
      } else {
        selectedDividendPayments.value = [];
      }
    });

    async function aplyFilter(dividendFilter: DividendsFilter) {
      await getDividents();
      if (dividendFilter.dateRange != undefined) {
        dividendPayments.value = dividendPayments.value.filter((dividend) => {
          if (dividendFilter.dateRange != undefined) {
            return (
              dividendFilter.dateRange[0] <= new Date(dividend.pendingDate) &&
              new Date(dividend.pendingDate) <= dividendFilter.dateRange[1]
            );
          }
        });
      }
      if (dividendFilter.filterDate != undefined) {
        const filterDateString = dividendFilter.filterDate
          .toISOString()
          .split("T")[0];

        dividendPayments.value = dividendPayments.value.filter((dividend) => {
          const dividendDate = new Date(dividend.pendingDate);
          const dividendDateString = dividendDate.toISOString().split("T")[0];

          if (filterDateString === dividendDateString) {
            return true;
          }
          return false;
        });
      }
      if (dividendFilter.shareholderId != undefined) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.shareholderId == dividendFilter.shareholderId;
        });
      }
      if (!dividendFilter.showPayed) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.status != DividendStatusType.PAID;
        });
      }
      if (!dividendFilter.showPending) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.status != DividendStatusType.PENDING;
        });
      }
      if (!dividendFilter.showRejected) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.status != DividendStatusType.REJECTED;
        });
      }
      if (dividendFilter.dividendId != undefined) {
        dividendPayments.value = dividendPayments.value.filter((dividen) => {
          return dividen.dividendId == dividendFilter.dividendId;
        });
      }
    }
    const updateSelectedItems = (selected: any) => {
      //alert("actualizando los seleccionados");

      selectedDividendPayments.value = selected;
      // selectedItems.value = selected;
    };
    async function modifyMultiplePayments() {
      if (selectedDividendPayments.value.length == 0) return;

      const multiplePaymentData = new MultiplePaymentsData(
        selectedDividendPayments.value,
        dividendNewStatus.value
      );
      const res = await dividendService.editMultiplePayment(
        multiplePaymentData
      );
      if (!res.data) {
        Swal.fire(
          "¡Error!",
          "Ocurrio un error inesperado, intente nuevamente",
          "error"
        );
      } else {
        Swal.fire(
          "¡Listo!",
          "Se ha cambiado el estado de los pagos seleccionados de forma exitosa",
          "success"
        );
      }
      selectedDividendPayments.value = [];
      areAllDividendsSelected.value = false;
      dividendFilter.value = new DividendsFilter();
      aplyFilter(dividendFilter.value);
      getDividents();
    }
    async function deleteHandler(dividendId: number, e: Event) {
      e.stopPropagation();
      isVisibleConfirmationModal.value = true;
      selectedDividendToDelete.value = dividendId;
    }
    async function deleteDividend() {
      dividendFilter.value = new DividendsFilter();
      aplyFilter(dividendFilter.value);
      if (selectedDividendToDelete.value == undefined) return null;
      let res = await dividendService.deleteDividend(
        selectedDividendToDelete.value
      );
      if (res.success) {
        //alert("dividendo eliminado con éxito.");
      }
      getAllDividends();
      getDividents();
      isVisibleConfirmationModal.value = false;
    }
    function backFilterDividendHandler() {
      dividendFilter.value = new DividendsFilter();
      aplyFilter(dividendFilter.value);

      router.push({ name: "dividends" });

      return false;
    }
    function filterDividendHandler(Id: number) {
      if (Id == -1) {
        dividendFilter.value = new DividendsFilter();
        aplyFilter(dividendFilter.value);

        return false;
      }
      dividendFilter.value.dividendId = Id;

      aplyFilter(dividendFilter.value);
    }
    function tableRowSelectionHandler(dividendSelected: PaymentStatus) {
      dividendDataSelected.value = dividendSelected;
      showDetailModal.value = true;
    }
    function closeFilterModal() {
      showFilterModal.value = showFilterModal.value
        ? false
        : showFilterModal.value;
    }
    function closeNewDividendModal() {
      showNewDividendModal.value = showNewDividendModal.value
        ? false
        : showNewDividendModal.value;
    }
    function closeNewDetailModal() {
      //dontRepit yourself
      showDetailModal.value = showDetailModal.value
        ? false
        : showDetailModal.value;
    }
    function getPrettyPrintStatus(status: DividendStatusType) {
      switch (status) {
        case DividendStatusType.PAID:
          return "Pagado";
        case DividendStatusType.PENDING:
          return "Pendiente";
        case DividendStatusType.REJECTED:
          return "Rechazado";
        default:
          break;
      }
      return status;
    }
    function genericButtonHandler(option: formOptionsType) {
      showNewDividendModal.value = false;
      showFilterModal.value = false;
      switch (option) {
        case formOptionsType.newDividendForm:
          showNewDividendModal.value = true;
          break;

        case formOptionsType.filterForm:
          showFilterModal.value = true;
          break;

        default:
          break;
      }
    }

    function downloadExcel(dividendId: number) {
      shareholderService.downloadExcel(dividendId);
    }

    return {
      route,
      genericButtonHandler,
      areAllDividendsSelected,
      closeFilterModal,
      closeNewDividendModal,
      reformatDate,
      getAllDividends,
      aplyFilter,
      getPrettyPrintStatus,
      tableRowSelectionHandler,
      getDividents,
      toBadgeFormat,
      closeNewDetailModal,
      modifyMultiplePayments,
      updateSelectedItems,
      dividendNewStatus,
      dividendFilter,
      deleteHandler,
      filterDividendHandler,
      deleteDividend,
      columnsUnits,
      columnsDividendos,
      isVisibleConfirmationModal,
      DividendLabelOptionType,
      selectedDividendPayments,
      dividendDataSelected,
      DividendStatusType,
      showDetailModal,
      showNewDividendModal,
      clickedOutsideModal,
      showFilterModal,
      formOptionsType,
      dividendPayments,
      dividends,
      showDeleteConfirmation,
      selectedDividendToDelete,
      backFilterDividendHandler,
      downloadExcel,
    };
  },
});
