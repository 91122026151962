
import { ParameterService } from "@/services/parameters/parameterService";
import { Parameter } from "@/services/parameters/typer";
import { defineComponent, computed, ref } from "vue";
import TextField from "@/components/atoms/TextField.vue";
import InputChip from "@/components/molecules/DateInputChip.vue";
// import DateWinTable from "@/components/organisms/DateWinTable.vue";
import ComponentCard from "@/components/atoms/ComponentCard.vue";

export default defineComponent({
  setup() {
    const parameters = ref<null | Parameter>(null);
    const parameterService = new ParameterService();
    const selection = ref<string>("");
    const dividendPaymentPeriodAux = ref<null | string[]>(null);
    const stockSalesWinAux = ref<null | string[][]>(null);
    const changed = ref<boolean>(false);

    //* ----- Geting Parameters------

    (async () => {
      let response = await parameterService.getParameters();
      if (!response.success) {
        console.log(
          "Ha ocurrido un error al intentar obtener la tarjeta de presentación del accionista"
        );
        return;
      }

      parameters.value = response.data;
      dividendPaymentPeriodAux.value = parameters.value.dividendPaymentPeriod;
      stockSalesWinAux.value = parameters.value.stockSaleWindows;
    })();

    const submitForm = async () => {
      let body = { ...parameters.value };
      if (dividendPaymentPeriodAux.value && stockSalesWinAux.value) {
        body.dividendPaymentPeriod = dividendPaymentPeriodAux.value;
        body.stockSaleWindows = stockSalesWinAux.value;
      }
      if (body) {
        let response = await parameterService.editParameters(body);
        changed.value = false;
        if (response) alert("Se han guardado los cambios exitosamente");
        return response;
      }
    };
    //* ----- dividendPaymentPeriod Functions ------
    //* ----- -----------------------
    const dropDate = (itemIndex: number) => {
      if (dividendPaymentPeriodAux.value) {
        dividendPaymentPeriodAux.value = dividendPaymentPeriodAux.value?.filter(
          (item, index) => {
            return index !== itemIndex;
          }
        );
      }
      changed.value = true;
    };
    const addDate = (item: string) => {
      dividendPaymentPeriodAux.value?.push(item);
    };
    //* ----- stockSaleWindows Functions ------
    //* ----- -----------------------
    const dropDateWin = (itemIndex: number) => {
      if (stockSalesWinAux.value) {
        stockSalesWinAux.value = stockSalesWinAux.value?.filter(
          (item, index) => {
            return index !== itemIndex;
          }
        );
      }
      changed.value = true;
    };
    const addDateWin = (item: string[]) => {
      stockSalesWinAux.value?.push(item);
      changed.value = true;
    };
    const editDateWin = (item: string[], index: number) => {
      stockSalesWinAux.value?.splice(index, 1, item);
      changed.value = true;
    };

    //* ----- Error Management ------
    //* ----- -----------------------
    const minMonthToSellError = computed(() => {
      return isNullOrEmpty(parameters.value?.minMonhtsToSellShares);
    });
    const ufPriceError = computed(() => {
      return isNullOrEmpty(parameters.value?.ufSharesPrice);
    });
    const minSharesError = computed(() => {
      return isNullOrEmpty(parameters.value?.minShares);
    });

    function isNullOrEmpty(value?: string | number | null): boolean {
      return !value || value == null || value == "" || value == 0;
    }

    const formHasError = computed(() => {
      const requiredFields = [
        parameters.value?.minMonhtsToSellShares,
        parameters.value?.ufSharesPrice,
        parameters.value?.minShares,
      ];
      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }
      const formErrors = [
        minMonthToSellError.value,
        ufPriceError.value,
        minSharesError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }
      return false;
    });

    return {
      parameters,
      selection,
      dividendPaymentPeriodAux,
      stockSalesWinAux,
      changed,
      dropDate,
      submitForm,
      addDate,
      dropDateWin,
      addDateWin,
      editDateWin,

      //* errors
      minMonthToSellError,
      ufPriceError,
      formHasError,
      minSharesError,
    };
  },
  components: {
    TextField,
    InputChip,
    // DateWinTable,
    ComponentCard,
  },
});
