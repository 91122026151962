import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-8 h-full box-shadow-profile mx-3 border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShareholderCard = _resolveComponent("ShareholderCard")!
  const _component_TabTemplate = _resolveComponent("TabTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ShareholderCard, { class: "col-span-8 border-b-[#9BB441] border-b-[20px] px-5" }),
    _createVNode(_component_TabTemplate, {
      class: "col-span-8 px-5",
      tabs: [
        { name: 'Identificación', view: _ctx.markRaw(_ctx.ShareholderIdentificationTab) },
        { name: 'Acciones', view: _ctx.markRaw(_ctx.ShareholderSharesTab) },
        {
          name: 'Notificaciones',
          view: _ctx.markRaw(_ctx.ShareholderNotificationSettingsTab),
        },
      ]
    }, null, 8, ["tabs"])
  ]))
}