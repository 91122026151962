import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse, keysToSnake, getResponsePagination } from "../utils";
import { Response, ResponsePagination } from "../types";
import { Investment, Record ,EditInvest, InvestmentRequest,UploadInvest} from "./types";

export class InvestmentService extends AuthenticatedService {
  async getInvestiments(investment_status:string,investment_type:string,page=0): Promise<ResponsePagination<Investment[]>> {
    let url = `${getEnvConfig().apiURL}/investments/all?`;

    if(page > 0){
      url+= `page=${page}`;
    }
    if(investment_status != ''){
      url+= `&investment_status=${investment_status}`;
    }
    if(investment_type != ''){
      url+= `&investment_type=${investment_type}`;
    }


    return getResponsePagination(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      Investment
    );
  }
  async getRecords(id: number): Promise<Response<Record[]>> {
    const url =
      `${getEnvConfig().apiURL}/investments/records/all/` + id.toString();

    return getResponse(url, this.token, "GET", undefined, undefined, Record);
  }
  async getLastRecordDate(id: number): Promise<Response<Date>> {
    const url =
      `${getEnvConfig().apiURL}/investments/records/get_last_record_date/` +
      id?.toString();

    return getResponse(url, this.token, "GET", undefined, undefined);
  }
  async uploadImage(uploadImage: UploadInvest) {
    const formUploadContractData = new FormData();
    
    formUploadContractData.append(
      "file",
      uploadImage.image
    );
    const url = `${getEnvConfig().apiURL}/investments/${uploadImage.id.toString()}/image `;

    return getResponse(
      url,
      this.token,
      "POST",
      undefined,
      formUploadContractData
    );
  }

  async createInvestiment(data: InvestmentRequest ): Promise<Response<Investment>> {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("investment_type", data.investmentType);
    formData.append("investment_status", data.investmentStatus);
    formData.append("value", data.value.toString());

    formData.append(
      "monthly_profitability_uf",
      data.monthlyProfitabilityUf ? data.monthlyProfitabilityUf.toString(): ''
    );
    formData.append(
      "monthly_profitability_clp",
      data.monthlyProfitabilityClp ? data.monthlyProfitabilityClp.toString(): ''
    );
    formData.append("thumbnail", data.thumbnail);
    formData.append("is_deleted", data.isDeleted.toString());

    const url = `${getEnvConfig().apiURL}/investments/create`;

    return getResponse(url, this.token, "POST", undefined, formData);
  }
  async createRecord(data: Record): Promise<Response<Record>> {
    const body = keysToSnake({
      title: data.title,
      description: data.description,
      investmentId: data.investmentId,
    });
    const url = `${getEnvConfig().apiURL}/investments/records/create`;

    return getResponse(url, this.token, "POST", body, undefined);
  }
  async editInvestiment(data: EditInvest): Promise<Response<EditInvest>> {
    const body = {
      id:data.id,
      title:data.title,
      description:data.description,
      value:data.value,
      monthly_profitability_uf:data.monthlyProfitabilityUf,
      monthly_profitability_clp:data.monthlyProfitabilityClp,
      investment_status: data.investmentStatus
    }
    const url = `${getEnvConfig().apiURL}/investments/edit`;
    return getResponse(url, this.token, "POST", body, undefined);
  }
}
