
import { defineComponent, ref } from "vue";
import Switch from "@/components/atoms/Switch.vue";

export default defineComponent({
  components: { Switch },

  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    actived: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["update:actived"],

  setup(props, { emit }) {
    function handleUpdate(event: boolean) {
      emit("update:actived", event);
    }

    return {
      handleUpdate,
    };
  },
});
