
import { computed, watch, defineComponent, ref } from "vue";
import TextField from "@/components/atoms/TextField.vue";
import Selector from "@/components/atoms/Selector.vue";
import {
  bankNames,
  bankAccountTypes,
  UserFetchData,
} from "@/services/auth/types";
import { useShareholderStore } from "@/stores/shareholder";
import { AuthService } from "@/services/auth/AuthService";
import {
  isNullOrEmpty,
  reformatDate,
  reformatDateISO8601,
  validateRut,
} from "@/services/utils";
import ChangePasswordForm from "@/components/molecules/ChangePasswordForm.vue";
import router from "@/router";
import Datepicker, {
  VueDatePicker,
  PublicMethods,
} from "@vuepic/vue-datepicker";
import Swal from "sweetalert2";

export default defineComponent({
  components: { TextField, Selector, ChangePasswordForm },

  data() {
    return {
      showModal: false,
    };
  },

  setup() {
    const showChangePasswordModal = ref<boolean>(false);
    const shareholderStore = useShareholderStore();
    const changed = ref<boolean>(false);
    const flow = ref<VueDatePicker["flow"]>(["month", "calendar"]);

    const shareholder = computed(() => shareholderStore.shareholder);
    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );
    type userResponse = {
      detail: string;
    };
    watch(
      shareholder,
      (value, oldValue) => {
        if (oldValue != undefined) {
          changed.value = true;
        }
      },
      { deep: true }
    );
    const investorNumberA = ref<number | null>(null);
    const investorNumberB = ref<number | null>(null);

    const rutFieldHasError = computed(() => {
      if (shareholder.value == undefined) return true;
      return (
        isNullOrEmpty(shareholder.value.rut) ||
        !validateRut(shareholder.value.rut)
      );
    });
    const authService = new AuthService();
    watch(
      shareholder,
      (newShareholder) => {
        if (newShareholder) {
          investorNumberA.value = newShareholder.investorNumberA
            ? newShareholder.investorNumberA
            : 0;
          investorNumberB.value = newShareholder?.investorNumberB
            ? newShareholder?.investorNumberB
            : 0;
        }
      },
      { immediate: true }
    );
    async function setWritingDate(date: string) {
      if (shareholder.value == undefined) return;
      shareholder.value.writingDate = reformatDateISO8601(new Date(date));
    }

    async function submitUser() {
      if (shareholder.value == undefined || rutFieldHasError.value) {
        alert("Algo no está en orden");
        return;
      }
      // investorNumberA.value = investorNumberA.value ? investorNumberA.value : null;
      // investorNumberB.value = investorNumberB.value ? investorNumberB.value : null;
      let userFetchData = new UserFetchData(
        shareholder.value.firstName,
        shareholder.value.lastName,
        shareholder.value.rut,
        shareholder.value.email,
        shareholder.value.phone,
        shareholder.value.address,
        shareholder.value.bankName,
        shareholder.value.accountType,
        shareholder.value.accountNumber,
        shareholder.value.accountOwnerName,
        shareholder.value.accountOwnerRut,
        shareholder.value.accountEmail,
        shareholderId.value,
        shareholder.value.county,
        shareholder.value.region,
        shareholder.value.investorNumberA ? null : investorNumberA.value,
        investorNumberB.value == shareholder.value?.investorNumberB
          ? null
          : investorNumberB.value,
        0,
        0,
        0,
        shareholder.value.businessName ?? "",
        shareholder.value.businessAddress ?? "",
        shareholder.value.businessRut ?? "",
        shareholder.value.writingDate ?? null,
        shareholder.value.notary ?? "",
        shareholder.value.repertoireNumber ?? ""
      );
      const response = await authService.editShareholder(userFetchData);
      if (response.success) {
        shareholderStore.query(shareholderId.value);
        Swal.fire(
          "¡Listo!",
          "La información se ha modificado de forma éxitosa",
          "success"
        );
      } else {
        const responseData = response.data as userResponse;
        Swal.fire("¡Error!", responseData.detail, "error");
      }
    }

    // Método para abrir el modal
    function openChangePasswordModal() {
      showChangePasswordModal.value = true;
    }

    return {
      changed,
      shareholder,
      investorNumberA,
      investorNumberB,
      showChangePasswordModal,
      openChangePasswordModal,
      bankNames,
      bankAccountTypes,
      submitUser,
      flow,
      setWritingDate,
      rutFieldHasError,
    };
  },
});
